import React, { useCallback, useEffect, useRef, useState } from "react";

import Crossword, {
  CrosswordImperative,
  CrosswordGrid,
  CrosswordProps,
  CrosswordProvider,
  CrosswordProviderImperative,
  CrosswordProviderProps,
  DirectionClues,
  useIpuz,
} from "@jaredreisinger/react-crossword";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "./components/cardLeaderboard";
import styles from "./style.module.css";

export default function GameCrossWord() {
  const crossword = useRef(null);

  const onCorrect = (direction, number, answer) => {
  
  };

  const onLoadedCorrect = (answers) => {
    
  };

  const onCrosswordCorrect = (isCorrect) => {
   handleChangeState(STATE.FINISH)
  };
  const onCellChange = (row, col, char) => {};
  const [state, setState] = useState(STATE.START);
  const [name, setName] = useState("");
  const [counter, setCounter] = useState(0);
  // useEffect(() => {
  //   if (state === STATE.GAME) {
  //     const timer =
  //       counter > 0 && setInterval(() => setCounter(counter + 1), 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [state, counter]);

  const reset = () => {
    crossword.current.reset();
  };

  const data = {
    across: {
      1: {
        clue: "Made of a very precious metal",
        answer: "GOLDEN",
        row: 0,
        col: 0,
      },
      5: {
        clue: "Article",
        answer: "AN",
        row: 1,
        col: 0,
      },
      6: {
        clue: "Not sweet",
        answer: "SOUR",
        row: 2,
        col: 2,
      },
      7: {
        clue: "At any time",
        answer: "EVER",
        row: 3,
        col: 0,
      },
      9: {
        clue: "That is (abbr.)",
        answer: "IE",
        row: 4,
        col: 1,
      },
      10: {
        clue: "Therefore",
        answer: "SO",
        row: 4,
        col: 4,
      },
      11: {
        clue: "Opening in a wall te let in light and air",
        answer: "WINDOW",
        row: 5,
        col: 0,
      },
    },
    down: {
      1: {
        clue: "Opening in a wall or fence for a passageway",
        answer: "GATE",
        row: 0,
        col: 0,
      },
      2: {
        clue: "Upon; above and supported by",
        answer: "ON",
        row: 0,
        col: 1,
      },
      3: {
        clue: "Entrance to a room or building",
        answer: "DOOR",
        row: 0,
        col: 3,
      },
      4: {
        clue: "Not wide",
        answer: "NARROW",
        row: 0,
        col: 5,
      },
      6: {
        clue: "Observed",
        answer: "SEEN",
        row: 2,
        col: 2,
      },
      8: {
        clue: "Seven (Roman numerals)",
        answer: "VII",
        row: 3,
        col: 1,
      },
      10: {
        clue: "Thus",
        answer: "SO",
        row: 4,
        col: 4,
      },
    },
  };

  useEffect(() => {
    if (state === STATE.GAME && crossword?.current !== null) {
      reset();
    }
  }, [state, crossword?.current]);

  const handleChangeState = (val) => {
    setState(val);
  };

  return (
    <div
      className="App flex justify-start items-start flex-col min-h-screen"
      style={{
        background:
          "url('https://eventqiu.com/assets/bg_game_dark.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
      }}
    >
      {/* <button onClick={() => reset()}>RESET</button> */}
      {/* START */}
      {state === STATE.START && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardStart
            onChangeState={(val) => handleChangeState(val)}
            setName={(val) => setName(val)}
          />
        </div>
      )}

      {/* GAME */}
      {state === STATE.GAME && (
        <>
          <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-none justify-between items-center w-full h-14">
            <h3 className="text-xl d:text-3xl font-bold text white w-full text-left">
              Crossword Game
            </h3>
            <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
              <div className="text-black font-semibold">
                User: <span className="text-black0 font-bold">{name}</span>{" "}
              </div>
              <span className="mx-2">|</span>
              <div className="text-black font-semibold">
                Timer: <span className="text-red-500 font-bold">{counter}</span>{" "}
              </div>
            </div>
          </header>

          <div className="flex justify-center items-center flex-grow w-full p-5">
            <div className="container" style={{ borderRadius: 0 }}>
              <div className="relative crossword">
                <CrosswordProvider
                  ref={crossword}
                  data={data}
                  // storageKey="first-example"
                  onCorrect={onCorrect}
                  onLoadedCorrect={onLoadedCorrect}
                  onCrosswordCorrect={onCrosswordCorrect}
                  onCellChange={onCellChange}
                >
                  <div className="hidden md:flex space-x-10 p-5">
                    <div className="w-full max-w-[200px]">
                      <DirectionClues direction="across" />
                    </div>
                    <div className="flex w-[500px] h-[500px]">
                      <CrosswordGrid />
                    </div>
                    <div className="w-full max-w-[200px]">
                      <DirectionClues direction="down" />
                    </div>
                  </div>

                  <div className="flex flex-col items-center md:hidden space-y-5 p-3">
                    <div className="flex w-full">
                      <CrosswordGrid />
                    </div>
                    <div className="grid grid-cols-2 gap-2 w-full">
                      <DirectionClues direction="across" />
                      <DirectionClues direction="down" />
                    </div>
                  </div>
                </CrosswordProvider>
              </div>
            </div>
          </div>
        </>
      )}

      {/* FINISH */}
      {state === STATE.FINISH && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardFinish
            name={name}
            onChangeState={(val) => handleChangeState(val)}
            score={0}
          />
        </div>
      )}

      {/* LEADERBOARD */}
      {state === STATE.LEADERBOARD && (
        <>
          <CardLeaderboard onChangeState={(val) => handleChangeState(val)} />
        </>
      )}
    </div>
  );
}
