import { useState } from "react";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardIntro from "./components/cardIntro";
import axios from "axios";
import CONFIG from "../../constanta/config";
import { useEffect } from "react";
import LiveGameMatchImage from "../../game/match-image/matchImage.live";
import LiveGameSearchWord from "../../game/search-word/search.live";
import LiveGameHangman from "../../game/hangman/hangman.live";
import LiveGameScrambleWord from "../../game/scramble-word/scrambleWord.live";
import LiveGameMaze from "../../game/maze/maze.live";
import LiveGameCrossWord from "../../game/cross-word/crossWord.live";
import Campaign from "../campaign/campaign";
import Leaderboard from "../leaderboard/leaderboard";
import moment from "moment";

const HomeLB = () => {
  const [state, setState] = useState(STATE.LEADERBOARD);
  const [user, setUser] = useState(null);
  const [game, setGame] = useState(null);
  const [score, setScore] = useState(0);
  const [activity, setActivity] = useState(null);
  const [round1, setRound1] = useState([]);
  const [round2, setRound2] = useState([]);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getActivity();
    getConfig();
  }, []);

  const getConfig = () => {
    setLoading(true);
    axios
      .get(CONFIG.URL + "/config?rnd=" + Date.now(), {})
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          setConfig(response.data.config);
        } else {
        }
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };

  const getActivity = () => {
    setLoading(true);
    axios
      .get(CONFIG.URL + "/activity?rnd=" + Date.now())
      .then(function (response) {
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          let arrRound1 = [];
          let arrRound2 = [];

          let i = 0;
          while (i < response.data?.data?.length) {
            if (
              response.data?.data[i].activity_round.toLowerCase() === "round 1"
            ) {
              arrRound1.push(response.data?.data[i]);
            } else {
              arrRound2.push(response.data?.data[i]);
            }

            i++;
          }

          setRound1(arrRound1);
          setRound2(arrRound2);
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  return (
    <>
      {loading ? (
        <div className="font-bold text-center text-white bg-black w-screen h-screen flex justify-center items-center">
          Loading...
        </div>
      ) : (
        <div
          className="App flex justify-start items-start flex-col min-h-screen "
          style={{
            background: `url('${config?.background}') no-repeat center center fixed`,
            backgroundSize: "cover",
          }}
        >
          {/* LEADERBOARD */}
          {state === STATE.LEADERBOARD && (
            <div className="flex justify-start items-start flex-grow w-full p-5">
              <Leaderboard actRound1={round1} actRound2={round2} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default HomeLB;
