import { useState } from "react";

const TextInput = ({
  name,
  label,
  placeholder,
  isRequired,
  isInvalid,
  register,
  color,
  otherInfo,
  actionOtherInfo,
  disabled = false,
}) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      <div className={`${isInvalid ? "mb-3" : "mb-3"} flex flex-col w-full `}>
        {label && (
          <div className="flex justify-between">
            <label className={`text-xs ${color ? color : "text-black-1"}`}>
              {label}
            </label>

            {otherInfo && (
              <label
                onClick={() => actionOtherInfo()}
                className={`cursor-pointer text-xs text-[#0944A1] underline`}
              >
                {otherInfo}
              </label>
            )}
          </div>
        )}
        <div className="relative w-full">
          <input
            disabled={disabled}
            type="text"
            onFocus={() => setFocus(true)}
            onBlurCapture={() => setFocus(false)}
            className={`${isInvalid ? "border-[#CB000F] rounded-" : "bg-white"} ${
              disabled ? "bg-gray-200" : ""
            } relative w-full text-sm text-black-1 border-2 border-white-1 rounded-[10px] h-[45px] py-[12px] px-[15px] focus:outline-none`}
            placeholder={placeholder}
            {...register(name, {
              required: isRequired ? true : false,
            })}
          />
          {isInvalid && (
            <div className="text-[#CB000F] text-sm font-semibold mt-1 flex items-end w-full  bg-red-1 ">
              {isInvalid.message}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TextInput;
