const ListGame = () => {
  return (
    <div
      className="App flex justify-start items-start flex-col min-h-screen p-5 space-y-2"
      style={{
        background:
          "url('https://eventqiu.com/assets/bg_game_dark.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
      }}
    >
      <a
        href="/memory"
        className="bg-[#ffffffd9] rounded-md p-5 font-bold w-full"
      >
        Memory Game
      </a>
      <a
        href="/maze"
        className="bg-[#ffffffd9] rounded-md p-5 font-bold w-full"
      >
        Maze Game
      </a>
      <a
        href="/crossword"
        className="bg-[#ffffffd9] rounded-md p-5 font-bold w-full"
      >
        Crossword Game
      </a>
      <a
        href="/scramble-word"
        className="bg-[#ffffffd9] rounded-md p-5 font-bold w-full"
      >
        Scramble Word Game
      </a>
      <a
        href="/hangman"
        className="bg-[#ffffffd9] rounded-md p-5 font-bold w-full"
      >
        Hangman Game
      </a>
      <a
        href="/search-word"
        className="bg-[#ffffffd9] rounded-md p-5 font-bold w-full"
      >
        Search Word Game
      </a>
    </div>
  );
};

export default ListGame;
