import { useState, useMemo, useEffect } from "react";
import { generateMaze, solve } from "./util";
import styles from "./style.module.css";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import imgArrow from "../../images/arrow.png";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "../cross-word/components/cardLeaderboard";
import CountdownTimer from "../../components/countdownTimer";
import Stopwatch from "../../components/stopwatch";
import axios from "axios";
import CONFIG from "../../constanta/config";
import CardDiskualifikasi from "../../components/cardDiskualifikasi";
import useKeypress from "../../components/keypress";


export default function LiveGameMaze(props) {
  const { user, game, idActivity, config } = props;
  const [state, setState] = useState();
  const [finish, setFinish] = useState(false);
  const [duration, setDuration] = useState(0);

  const [gameId, setGameId] = useState(1);
  const [status, setStatus] = useState("playing");

  const [size, setSize] = useState(0);
  const [cheatMode, setCheatMode] = useState(false);

  const [userPosition, setUserPosition] = useState([0, 0]);
  const [maze, setMaze] = useState(null);

  useKeypress("ArrowRight", onKeyPress);
  useKeypress("ArrowLeft", onKeyPress);
  useKeypress("ArrowUp", onKeyPress);
  useKeypress("ArrowDown", onKeyPress);

  function onKeyPress(e) {
    handleMove(e);
  }

  useEffect(() => {
    const handler = (event) => {
      if (state !== STATE.FINISH && state !== STATE.FAIL) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [state]);

  useEffect(() => {
    if (props.score !== "" && props.score !== "0") {
      setDuration(props.score);
      setState(STATE.FINISH);
    } else if (props.score === "0") {
      setState(STATE.FAIL);
    } else {
      setState(STATE.START);
    }
  }, [props.score]);

  // const GameSetting = {
  //   size: 20,
  // };

  // const maze = useMemo(() => generateMaze(size, size), [size, gameId]);

  useEffect(() => {
    if (game) {
      let setting = JSON.parse(game.game_setting);
      setSize(setting?.size);
      setMaze(generateMaze(setting?.size, setting?.size), [
        setting?.size,
        gameId,
      ]);
    }
  }, [game]);

  const solution = useMemo(() => {
    if (maze !== null) {
      const s = new Set();
      const solutionPath = solve(maze, userPosition[0], userPosition[1]);
      solutionPath.forEach((path) => {
        const [x, y] = path;
        s.add(String(x) + "-" + String(y));
      });
      return s;
    }
  }, [size, userPosition[0], userPosition[1], gameId]);

  useEffect(() => {
    if (maze !== null) {
      const lastRowIndex = maze.length - 1;
      const lastColIndex = maze[0].length - 1;
      if (
        userPosition[0] === lastRowIndex &&
        userPosition[1] === lastColIndex
      ) {
        postScore();
        setStatus("won");
        setFinish(true);
      }
    }
  }, [userPosition[0], userPosition[1]]);

  const postScore = async () => {
    const payload = {
      id_activity: idActivity,
      duration: duration,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/score?rnd=" + Date.now(), form_data, {
        headers: {
          token: user?.token,
        },
      })
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          setState(STATE.FINISH);
        } else {
        }
        setFinish(false);
      })
      .catch(function (error) {});
  };

  const makeClassName = (i, j) => {
    if (maze !== null) {
      const rows = maze.length;
      const cols = maze[0].length;
      let arr = [styles.wall];
      if (maze[i][j][0] === 0) {
        arr.push(styles.topWall);
      }
      if (maze[i][j][1] === 0) {
        arr.push(styles.rightWall);
      }
      if (maze[i][j][2] === 0) {
        arr.push(styles.bottomWall);
      }
      if (maze[i][j][3] === 0) {
        arr.push(styles.leftWall);
      }
      if (i === rows - 1 && j === cols - 1) {
        arr.push(styles.destination);
      }
      if (i === userPosition[0] && j === userPosition[1]) {
        arr.push(styles.currentPosition);
      }

      if (cheatMode && solution.has(String(i) + "-" + String(j))) {
        arr.push(styles.sol);
      }
      return arr.join(" ");
    }
  };

  const handleMove = (e) => {
    if (maze !== null) {
      e.preventDefault();
      if (status !== "playing") {
        return;
      }
      const key = e.code;

      const [i, j] = userPosition;
      if ((key === "ArrowUp" || key === "KeyW") && maze[i][j][0] === 1) {
        setUserPosition([i - 1, j]);
      }
      if ((key === "ArrowRight" || key === "KeyD") && maze[i][j][1] === 1) {
        setUserPosition([i, j + 1]);
      }
      if ((key === "ArrowDown" || key === "KeyS") && maze[i][j][2] === 1) {
        setUserPosition([i + 1, j]);
      }
      if ((key === "ArrowLeft" || key === "KeyA") && maze[i][j][3] === 1) {
        setUserPosition([i, j - 1]);
      }
    }
  };

  const handleMoveButton = (key) => {
    if (maze !== null) {
      if (status !== "playing") {
        return;
      }

      const [i, j] = userPosition;
      if (key === "up" && maze[i][j][0] === 1) {
        setUserPosition([i - 1, j]);
      }
      if (key === "right" && maze[i][j][1] === 1) {
        setUserPosition([i, j + 1]);
      }
      if (key === "down" && maze[i][j][2] === 1) {
        setUserPosition([i + 1, j]);
      }
      if (key === "left" && maze[i][j][3] === 1) {
        setUserPosition([i, j - 1]);
      }
    }
  };

  return (
    <>
      <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-col md:flex-row flex-none justify-between items-center w-full h-auto py-2 md:py-0 md:h-14">
        <h3 className="text-xl md:text-2xl font-bold text white w-full text-center md:text-left">
          {game?.name}
        </h3>
        <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
          <div className="text-black font-semibold">
            <span className="text-black-0 font-bold text-xl md:text-2xl">
              {user?.first_name} {user?.last_name} -{" "}
              <span className="text-gray-500">{user?.departement}</span>
            </span>{" "}
          </div>
        </div>
      </header>

      {finish && (
        <div className="fixed z-50 h-screen w-screen bg-[#000000b3] text-white flex justify-center items-center font-semibold text-lg">
          Please wait to submit score...
        </div>
      )}

      {state === STATE.START && (
        <CountdownTimer onComplete={() => setState(STATE.GAME)} />
      )}

      {maze !== null && state === STATE.GAME && (
        <>
          <div className="flex flex-col justify-center items-center flex-grow mx-auto md:p-5">
            <div className="flex items-end space-x-3 text-white font-semibold text-xl mb-2">
              <span>Time:</span>
              <Stopwatch
                playing={state === STATE.GAME && !finish}
                setDuration={(val) => setDuration(val)}
              />
            </div>
            <div
              className="container relative"
              style={{ borderRadius: 0, background: "#42ade2" }}
            >
              <div className="absolute -top-8 -left-2 bg-[#fa8072] py-1 px-2 rounded-full text-black font-bold text-[10px]">
                START
              </div>
              <div className="absolute -bottom-8 -right-2 bg-[#00ff00] py-1 px-2 rounded-full text-black font-bold text-[10px]">
                FINISH
              </div>

              <div
                className={`relative w-[95vw] h-[95vw] md:w-[72vh] md:h-[72vh] mx-auto outline-none focus-within:outline-none focus:outline-none`}
              >
                <table id="maze" className="w-full h-full">
                  <tbody>
                    {maze.map((row, i) => (
                      <tr key={`row-${i}`}>
                        {row.map((cell, j) => (
                          <td
                            key={`cell-${i}-${j}`}
                            className={makeClassName(i, j)}
                          >
                            <div />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            className={`md:hidden py-10 flex justify-between items-center  w-[95vw] h-[50px] mx-auto outline-none focus-within:outline-none focus:outline-none`}
          >
            <div className="flex space-x-4">
              <div
                onClick={() => handleMoveButton("down")}
                className=" cursor-pointer bg-white hover:bg-green-100 rounded-md p-2"
              >
                <img
                  src={imgArrow}
                  alt=""
                  className="rotate-90  h-[40px] w-[40px] object-contain"
                />
              </div>
              <div
                onClick={() => handleMoveButton("up")}
                className=" cursor-pointer bg-white hover:bg-green-100 rounded-md p-2"
              >
                <img
                  src={imgArrow}
                  alt=""
                  className="-rotate-90 h-[40px] w-[40px] object-contain"
                />
              </div>
            </div>

            <div className=" flex space-x-4">
              <div
                onClick={() => handleMoveButton("left")}
                className=" cursor-pointer bg-white hover:bg-green-100 rounded-md p-2"
              >
                <img
                  src={imgArrow}
                  alt=""
                  className="rotate-180 h-[40px] w-[40px] object-contain"
                />
              </div>
              <div
                onClick={() => handleMoveButton("right")}
                className=" cursor-pointer bg-white hover:bg-green-100 rounded-md p-2"
              >
                <img
                  src={imgArrow}
                  alt=""
                  className="h-[40px] w-[40px] object-contain"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {state === STATE.FINISH && (
        <div className="flex flex-col justify-center items-center flex-grow w-full p-5">
          <CardFinish duration={duration} user={user} game={game} />
        </div>
      )}

      {state === STATE.FAIL && (
        <div className="flex flex-col justify-center items-center flex-grow w-full p-5">
          <CardDiskualifikasi
            duration={duration}
            user={user}
            game={game}
            config={config}
          />
        </div>
      )}
    </>
  );
}
