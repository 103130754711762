import axios from "axios";
import { useEffect, useState } from "react";
import CONFIG from "../../constanta/config";

const CardLead = (props) => {
  const [open, setOpen] = useState(props.open || false);

  const formatDuration = (duration, align) => {
    return (
      <div className="font-bold  w-full">
        <div
          className={`flex ${
            align === "left" ? "justify-start" : "justify-end"
          }  items-center w-full`}
        >
          <div className="flex flex-col ">
            <span>{("0" + Math.floor((duration / 60000) % 60)).slice(-2)}</span>
          </div>
          <div className="flex flex-col mx-0.5">
            <span>:</span> <span className="text-base"></span>
          </div>
          <div className="flex flex-col ">
            <span>{("0" + Math.floor((duration / 1000) % 60)).slice(-2)}</span>
          </div>
          <div className="flex flex-col mx-0.5">
            <span>.</span> <span className="text-base"></span>
          </div>
          <div className="flex flex-col ">
            <span>{("00" + ((duration / 1) % 1000)).slice(-3)}</span>
          </div>
        </div>
      </div>
    );
  };

  const showScoreActivity = (id, list) => {
    let act = list.find((item) => item.id_activity === id);

    if (act !== undefined) {
      return formatDuration(act.duration, "left");
    } else return 0;
  };

  return (
    <div>
      <div className="w-full">
        <div
          onClick={() => setOpen(!open)}
          className={`${
            open ? "rounded-t-xl" : "rounded-xl"
          } cursor-pointer flex justify-between px-4 py-2 bg-[#42ade2d4] font-semibold text-white`}
        >
          <div className="text-left uppercase">
            TERRITORY {props.item.territory}
          </div>
          <div className="text-left">{open ? "-" : "+"}</div>
        </div>
        <div
          className={`${
            open ? "h-auto px-4 py-2" : "h-0 px-4 py-0"
          } overflow-x-auto w-full flex flex-col rounded-b-xl justify-between  bg-[#2b444f] font-semibold text-white`}
        >
          <div className="text-sm w-full rounded-b-xl flex justify-start px-4 py-2 bg-[#2b444f] font-semibold text-white">
            <div className="flex flex-none w-12 mr-4 text-left">PLACE</div>
            <div className="flex flex-none w-40 mr-4  text-left">EMPLOYEE</div>
            <div className="flex flex-none w-28 mr-4 text-left">DEPARTMENT</div>
            {props?.actRound?.map((item, key) => {
              return (
                <div
                  key={key}
                  className="uppercase flex flex-none w-32 mr-4 text-left"
                >
                  {item.activity_name}
                </div>
              );
            })}
            <div className="flex flex-grow w-full mr-4 text-right justify-end">
              TOTAL
            </div>
          </div>

          {props.item.score.map((user, userkey) => {
            return (
              <div
                key={userkey}
                className={`${
                  userkey % 2 === 0 ? "bg-[#3d5b68]" : "bg-[#4b626d]"
                } text-sm w-max md:w-full flex justify-start px-4 py-2 font-semibold text-white`}
              >
                <div className="flex  flex-none w-12 mr-4 text-center">
                  {userkey + 1}
                </div>
                <div className="flex flex-none w-40 mr-4 text-left">
                  {user?.first_name} {user?.last_name}
                </div>
                <div className="flex flex-none w-28 mr-4 text-left">
                  {user?.departement}
                </div>
                {props?.actRound?.map((item, key) => {
                  return (
                    <div
                      key={key}
                      className="flex flex-none w-32 mr-4 text-left"
                    >
                      {showScoreActivity(item.id, user?.duration_activity)}
                    </div>
                  );
                })}
                <div className="flex flex-grow w-full mr-4 text-right justify-end">
                  {formatDuration(user?.total_duration)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const Leaderboard = (props) => {
  const [loading, setLoading] = useState(false);
  const [round1, setRound1] = useState([]);
  const [round2, setRound2] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    axios
      .get(CONFIG.URL + "/leaderboard?rnd=" + Date.now())
      .then(function (response) {
        setLoading(false);
        if (response.data.status === "SUCCESS") {
          let arrRound1 = [];
          let arrRound2 = [];

          let i = 0;
          while (i < response.data?.data?.length) {
            if (response.data?.data[i].round.toLowerCase() === "round 1") {
              arrRound1.push(response.data?.data[i]);
            } else {
              arrRound2.push(response.data?.data[i]);
            }

            i++;
          }

          setRound1(arrRound1);
          setRound2(arrRound2);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="w-full px-0 flex justify-center items-center">
      <div className="rounded-lg w-full md:h-max flex flex-col justify-between items-start px-5 w-full bg-[#ffffffd9] border-[4px] py-2 md:py-4">
        {loading ? (
          <div className="font-bold text-center w-full">Loading...</div>
        ) : (
          <>
            <div className="w-full flex justify-between space-x-5 uppercase font-bold text-2xl mb-3">
              <div className="flex space-x-5">
                <a href="/RBLCPL2023"
                  className="font-bold cursor-pointer w-full flex justify-center items-center text-sm  text-white  px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
                >
                  BACK
                </a>

                <div>LEADERBOARD </div>
              </div>
              <div
                onClick={() => {
                  getData();
                }}
                className=" font-bold cursor-pointer w-max flex justify-center items-center text-sm  text-white  px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
              >
                REFRESH
              </div>
            </div>

            {round1.length > 0 && (
              <>
                {" "}
                <div className="uppercase font-bold text-xl mb-2 underline">
                  ROUND 1
                </div>
                <div className="space-y-2 w-full">
                  {round1?.map((item, key) => {
                    return (
                      <CardLead
                        actRound={props?.actRound1}
                        item={item}
                        key={key}
                        open={key === 0}
                      />
                    );
                  })}
                </div>
              </>
            )}

            {round2.length > 0 && (
              <>
                {" "}
                <div className="uppercase font-bold text-xl mb-2 underline mt-5">
                  ROUND 2
                </div>
                <div className="space-y-2 w-full">
                  {round2?.map((item, key) => {
                    return (
                      <CardLead
                        actRound={props?.actRound2}
                        item={item}
                        key={key}
                        open={false}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Leaderboard;
