import { useState } from "react";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardIntro from "./components/cardIntro";
import axios from "axios";
import CONFIG from "../../constanta/config";
import { useEffect } from "react";
import LiveGameMatchImage from "../../game/match-image/matchImage.live";
import LiveGameSearchWord from "../../game/search-word/search.live";
import LiveGameHangman from "../../game/hangman/hangman.live";
import LiveGameScrambleWord from "../../game/scramble-word/scrambleWord.live";
import LiveGameMaze from "../../game/maze/maze.live";
import LiveGameCrossWord from "../../game/cross-word/crossWord.live";
import Campaign from "../campaign/campaign";
import Leaderboard from "../leaderboard/leaderboard";
import moment from "moment";

const Home = () => {
  const [state, setState] = useState(STATE.CAMPAIGN);
  const [user, setUser] = useState(null);
  const [game, setGame] = useState(null);
  const [score, setScore] = useState(0);
  const [activity, setActivity] = useState(null);
  const [round1, setRound1] = useState([]);
  const [round2, setRound2] = useState([]);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (user?.token) {
      getGame();
      checkScore();
    }
  }, [user]);

  const getConfig = () => {
    setLoading(true);
    axios
      .get(CONFIG.URL + "/config?rnd=" + Date.now(), {})
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          setConfig(response.data.config);
        } else {
        }
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
      });
  };

  const getGame = () => {
    //activity?.id_game
    axios
      .get(CONFIG.URL + "/game/" + activity?.id_game + "?rnd=" + Date.now(), {
        headers: {
          token: user?.token,
        },
      })
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          setGame(response.data.data[0]);
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const handleLogin = (data) => {
    // console.log(JSON.stringify(data));
    setUser(data);
    setState(STATE.INTRO);
  };

  const checkScore = () => {
    let leaderboard = user?.score?.find(
      (item) => item.id_activity === activity?.id
    );
    if (leaderboard !== undefined && leaderboard?.duration !== "") {
      setScore(leaderboard?.duration);
    } else {
      setScore("");
    }
  };

  const handleBegin = () => {
    setState(STATE.GAME);
  };

  const showGame = () => {
    // eslint-disable-next-line default-case
    switch (game?.game_type) {
      case "search-word":
        return (
          <LiveGameSearchWord
            user={user}
            game={game}
            idActivity={activity?.id}
            score={score}
            config={config}
          />
        );

      case "hangman":
        return (
          <LiveGameHangman
            user={user}
            game={game}
            idActivity={activity?.id}
            score={score}
            config={config}
          />
        );

      case "scramble-word":
        return (
          <LiveGameScrambleWord
            user={user}
            game={game}
            idActivity={activity?.id}
            score={score}
            config={config}
          />
        );

      case "maze":
        return (
          <LiveGameMaze
            user={user}
            game={game}
            idActivity={activity?.id}
            score={score}
            config={config}
          />
        );

      case "memory":
        return (
          <LiveGameMatchImage
            user={user}
            game={game}
            idActivity={activity?.id}
            score={score}
            config={config}
          />
        );

      case "crosswords":
        return (
          <LiveGameCrossWord
            user={user}
            game={game}
            idActivity={activity?.id}
            config={config}
          />
        );
    }
  };

  const handleChooseActivity = (val) => {
    setActivity(val);
    setState(STATE.START);
  };

  return (
    <>
      {loading ? (
        <div className="font-bold text-center text-white bg-black w-screen h-screen flex justify-center items-center">
          Loading...
        </div>
      ) : (
        <div
          className="App flex justify-start items-start flex-col min-h-screen "
          style={{
            background: `url('${config?.background}') no-repeat center center fixed`,
            backgroundSize: "cover",
          }}
        >
          {/* CAMPAIGN */}
          {state === STATE.CAMPAIGN && (
            <div className="flex justify-center items-center flex-grow w-full p-3">
              <Campaign
                handleChooseActivity={(val) => handleChooseActivity(val)}
                goLead={() => setState(STATE.LEADERBOARD)}
                setRound1={(data) => setRound1(data)}
                setRound2={(data) => setRound2(data)}
                config={config}
              />
            </div>
          )}

          {/* LEADERBOARD */}
          {state === STATE.LEADERBOARD && (
            <div className="flex justify-start items-start flex-grow w-full p-5">
              <Leaderboard
                leaveLead={() => setState(STATE.CAMPAIGN)}
                actRound1={round1}
                actRound2={round2}
              />
            </div>
          )}

          {/* START */}
          {state === STATE.START && (
            <div className="flex justify-center items-center flex-grow w-full p-3">
              <CardStart
                handleLogin={(val) => handleLogin(val)}
                onBack={() => setState(STATE.CAMPAIGN)}
                config={config}
              />
            </div>
          )}

          {/* INTRO */}
          {state === STATE.INTRO && (
            <div className="flex justify-center items-center flex-grow w-full  p-3">
              <CardIntro
                user={user}
                game={game}
                config={config}
                activity={activity}
                handleBegin={() => handleBegin()}
                score={score}
                onBack={() => setState(STATE.START)}
              />
            </div>
          )}

          {/* GAME */}
          {state === STATE.GAME && <>{showGame()}</>}
        </div>
      )}
    </>
  );
};

export default Home;
