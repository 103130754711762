import { useState, useMemo, useEffect } from "react";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "../cross-word/components/cardLeaderboard";
import ALPHABET from "../../constanta/alphabet";
import imgCross from "../../assets/icon_cross.png";
import axios from "axios";
import CountdownTimer from "../../components/countdownTimer";
import Stopwatch from "../../components/stopwatch";
import CONFIG from "../../constanta/config";
import CardDiskualifikasi from "../../components/cardDiskualifikasi";

export default function LiveGameHangman(props) {
  const { user, game, idActivity, config } = props;
  const [state, setState] = useState();
  const [finish, setFinish] = useState(false);
  const [duration, setDuration] = useState(0);
  const [gameSetting, setGameSetting] = useState(null);
  const [questions, setQuestions] = useState([]);

  const [boxAnswer, setBoxAnswer] = useState([]);
  const [boxOption, setBoxOption] = useState([]);
  const [no, setNo] = useState(0);
  const [correct, setCorrect] = useState(0);
  const [currLive, setCurrLive] = useState(0);
  const [live, setLive] = useState([]);
  const [canCheck, setCheck] = useState(false);
  const [flashCorrect, setFlashCorrect] = useState(false);

  useEffect(() => {
    const handler = (event) => {
      if (state !== STATE.FINISH && state !== STATE.FAIL) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [state]);

  useEffect(() => {
    if (props.score !== "" && props.score !== "0") {
      setDuration(props.score);
      setState(STATE.FINISH);
    } else if (props.score === "0") {
      setState(STATE.FAIL);
    } else {
      setState(STATE.START);
    }
  }, [props.score]);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  useEffect(() => {
    if (game) {
      let setting = JSON.parse(game.game_setting);
      setGameSetting(setting);
      let words = setting.words.map((item) => {
        return {
          answer: item.toLowerCase(),
        };
      });

      setCurrLive(setting?.max_life);
      setQuestions(shuffle(words));
    }
  }, [game]);

  useEffect(() => {
    if (questions.length > 0) {
      setUpQuestion();
    }
  }, [questions]);

  useEffect(() => {
    if (no !== 0) {
      setUpQuestion();
    }
  }, [no]);
  const handleChangeState = (val) => {
    setState(val);
  };

  const setUpQuestion = () => {
    let char = [...questions[no]?.answer.split("")];
    let answer = char.map((item) => {
      return {
        char: "",
        guide: item,
        show: false,
      };
    });

    setBoxAnswer(answer);

    let option = ALPHABET.map((item, key) => {
      return {
        char: item,
        active: false,
        idOption: key,
      };
    });
    setBoxOption([...option]);

    let _live = [];
    let i = 0;
    while (i < gameSetting?.max_life) {
      _live.push({
        active: true,
      });
      i++;
    }

    setLive(_live);
  };

  const chooseLetter = (val, idOption) => {
    setCheck(true);
    let tempOption = [...boxOption];
    let option = tempOption.find((item) => item.idOption === idOption);
    option.active = true;
    setBoxOption(tempOption);

    let char = [...questions[no]?.answer.split("")];
    let arrIdx = [];
    let i = 0;
    while (i < char.length) {
      if (val.toLowerCase() === char[i]) {
        arrIdx.push(i);
      }
      i++;
    }

    if (arrIdx.length > 0) {
      let temp = [...boxAnswer];
      let j = 0;
      while (j < arrIdx.length) {
        temp[arrIdx[j]].char = val;
        temp[arrIdx[j]].show = true;
        j++;
      }

      setBoxAnswer(temp);
    } else {
      let leftLive = currLive - 1;
      if (leftLive === 0) {
        let _no = no + 1;
        if (_no >= gameSetting?.words?.length) _no = 1;

        setCurrLive(gameSetting?.max_life);
        setNo(_no);
        setUpQuestion();
      } else {
        let _live = [...live];
        _live[leftLive].active = false;
        setLive(_live);
        setCurrLive(leftLive);
      }
    }
  };

  useEffect(() => {
    if (canCheck) {
      let showNotValid = boxAnswer.find(
        (item) => item.show === false && item.guide !== " "
      );

      if (!showNotValid) {
        if (correct === gameSetting.max_puzzle - 1) {
          setFinish(true);
          setFlashCorrect(true);
          postScore();
        } else {
          setFlashCorrect(true);

          setTimeout(() => {
            let _no = no + 1;
            if (_no >= gameSetting?.words?.length) _no = 1;

            setCorrect(correct + 1);
            setCurrLive(gameSetting?.max_life);
            setNo(_no);
            setUpQuestion();
            setFlashCorrect(false);
          }, 500);
        }
      }
    }
  }, [boxAnswer]);

  const postScore = async () => {
    const payload = {
      id_activity: idActivity,
      duration: duration,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/score?rnd=" + Date.now(), form_data, {
        headers: {
          token: user?.token,
        },
      })
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          handleChangeState(STATE.FINISH);
        } else {
        }
        setFinish(false);
      })
      .catch(function (error) {});
  };

  return (
    <>
      <>
        <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-col md:flex-row flex-none justify-between items-center w-full h-auto py-2 md:py-0 md:h-14">
          <h3 className="text-xl md:text-2xl font-bold text white w-full text-center md:text-left">
            {game?.name}
          </h3>
          <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
            <div className="text-black font-semibold">
              <span className="text-black-0 font-bold text-xl md:text-2xl">
                {user?.first_name} {user?.last_name} -{" "}
                <span className="text-gray-500">{user?.departement}</span>
              </span>{" "}
            </div>
          </div>
        </header>

        {finish && (
          <div className="fixed z-50 h-screen w-screen bg-[#000000b3] text-white flex justify-center items-center font-semibold text-lg">
            Please wait to submit score...
          </div>
        )}

        {state === STATE.START && (
          <CountdownTimer onComplete={() => setState(STATE.GAME)} />
        )}

        {state === STATE.GAME && gameSetting !== null && (
          <div className="flex flex-col justify-center items-center flex-grow w-full md:p-5">
            <div className="text-white font-semibold text-xl mb-2">
              {correct} / {gameSetting.max_puzzle}
            </div>
            <div className="flex items-end space-x-3 text-white font-semibold text-xl mb-2">
              <span>Time:</span>
              <Stopwatch
                playing={state === STATE.GAME && !finish}
                setDuration={(val) => setDuration(val)}
              />
            </div>
            <div className="flex space-x-2 mt-1 mb-3">
              {live?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="relative bg-white border-2 border-white"
                  >
                    <img
                      src={"https://eventqiu.com/assets/CPL_Tile.jpg"}
                      className={`w-14 object-contain`}
                      w={70}
                      h={70}
                      alt=""
                    />

                    {!item.active && (
                      <img
                        src={imgCross}
                        className={`p-1 absolute top-0 left-0 w-14 object-contain`}
                        w={70}
                        h={70}
                        alt=""
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="container relative p-3 w-max">
              <div className="flex justify-center items-center flex-wrap font-semibold md:text-xl break-words px-3 w-full md:w-full">
                {gameSetting?.text_category}
              </div>
            </div>
            <div className="flex flex-wrap w-full justify-center items-center my-5">
              {boxAnswer?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className={`mb-2 ${
                      item.guide === " "
                        ? "opacity-0 w-full h-0 md:w-[50px] md:h-[60px]"
                        : " w-[30px] h-[40px] md:w-[50px] md:h-[60px]"
                    }   ${
                      flashCorrect
                        ? "text-green-400 border-green-400"
                        : "text-white border-white"
                    } mx-1 mb-1  border-b-[4px]  relative   flex justify-center items-center`}
                    style={{ textShadow: "2px 1px 2px rgba(0, 0, 0, 0.88)" }}
                  >
                    <div className={`font-bold md:text-4xl uppercase`}>
                      {item.char}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="container relative p-2 md:p-3 w-max">
              <div className="flex flex-wrap items-center justify-center w-[300px] md:w-[800px]">
                {boxOption?.map((item, key) => {
                  return (
                    <div
                      onClick={() => {
                        if (!item.active) {
                          chooseLetter(item.char, item.idOption);
                        }
                      }}
                      key={key}
                      className={`cursor-pointer ${
                        item.active ? "bg-yellow-600" : "bg-yellow-300"
                      } hover:bg-yellow-600 mx-1 mb-1 border-[2px] border-yellow-700 rounded-[6px] md:rounded-[16px] w-[40px] h-[40px] relative md:w-[50px] md:h-[50px] flex justify-center items-center`}
                    >
                      <div className="font-bold md:text-2xl uppercase">
                        {item.char}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {state === STATE.FINISH && (
          <div className="flex flex-col justify-center items-center flex-grow w-full p-5">
            <CardFinish duration={duration} user={user} game={game} />
          </div>
        )}

        {state === STATE.FAIL && (
          <div className="flex flex-col justify-center items-center flex-grow w-full p-5">
            <CardDiskualifikasi
              duration={duration}
              user={user}
              game={game}
              config={config}
            />
          </div>
        )}
      </>
    </>
  );
}
