import { useEffect, useState, useRef } from "react";
import Card from "./card";
import CardStart from "./components/cardStart";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "./components/cardLeaderboard";
import axios from "axios";
import STATE from "../../constanta/state";

const uniqueElementsArray = [
  {
    type: "1",
    image: "https://eventqiu.com/assets/quiz-image/1.jpg",
  },
  {
    type: "2",
    image: "https://eventqiu.com/assets/quiz-image/2.jpg",
  },
  {
    type: "3",
    image: "https://eventqiu.com/assets/quiz-image/3.jpg",
  },
  {
    type: "4",
    image: "https://eventqiu.com/assets/quiz-image/4.jpg",
  },
  {
    type: "5",
    image: "https://eventqiu.com/assets/quiz-image/5.jpg",
  },
  {
    type: "6",
    image: "https://eventqiu.com/assets/quiz-image/6.jpg",
  },
  {
    type: "7",
    image: "https://eventqiu.com/assets/quiz-image/7.jpg",
  },
  {
    type: "8",
    image: "https://eventqiu.com/assets/quiz-image/8.jpg",
  },
];

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

const GameMatchImage = (props) => {
  const [totalScore, setTotalScore] = useState(0);
  const [state, setState] = useState(STATE.START);
  const [name, setName] = useState("");

  const [counter, setCounter] = useState(0);
  // useEffect(() => {
  //   if (state === STATE.GAME) {
  //     const timer =
  //       counter > 0 && setInterval(() => setCounter(counter + 1), 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [state, counter]);

  const [cards, setCards] = useState(
    shuffleCards.bind(null, uniqueElementsArray.concat(uniqueElementsArray))
  );
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const [moves, setMoves] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [bestScore, setBestScore] = useState(
    JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
  );
  const timeout = useRef(null);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === uniqueElementsArray.length) {
      //alert("Complete!!!, please button restart for play game again.");
      setShowModal(true);
      const highScore = Math.min(moves, bestScore);
      setBestScore(highScore);
      localStorage.setItem("bestScore", highScore);

      let tot = 0;

      if (counter > 0 && counter <= 30) tot = 5;
      else if (counter > 30 && counter <= 45) tot = 4;
      else if (counter > 45 && counter <= 60) tot = 3;
      else if (counter > 60 && counter <= 75) tot = 2;
      else if (counter > 75) tot = 1;

      setTotalScore(tot);
      postScore(tot);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  const url = "https://gameqiu.jagoan.info/api";
  const getSetting = () => {
    axios
      .get(url + "/game/2")
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  };

  const postScore = (tot) => {
    var formData = new FormData();
    formData.append("id_game", "2");
    formData.append("name", name);
    formData.append("score", tot);
    formData.append("duration", counter);

    axios({
      method: "post",
      url: "https://eventqiu.jagoan.info/api/score",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        handleChangeState("FINISH");
      })
      .catch(function (response) {
        postScore(tot);
      });
  };

  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      setMoves((moves) => moves + 1);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  const handleRestart = () => {
    setClearedCards({});
    setOpenCards([]);
    setShowModal(false);
    setMoves(0);
    setShouldDisableAllCards(false);
    // set a shuffled deck of cards
    setCards(shuffleCards(uniqueElementsArray.concat(uniqueElementsArray)));
  };

  const handleChangeState = (val) => {
    setState(val);
  };

  return (
    <div
      className="App flex justify-start items-start flex-col min-h-screen"
      style={{
        background:
          "url('https://eventqiu.com/assets/bg_game_dark.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
      }}
    >
      {/* START */}
      {state === STATE.START && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardStart
            onChangeState={(val) => handleChangeState(val)}
            setName={(val) => setName(val)}
          />
        </div>
      )}

      {/* GAME */}
      {state === STATE.GAME && (
        <>
          <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-none justify-between items-center w-full h-14">
            <h3 className="text-xl d:text-3xl font-bold text white w-full text-left">
              Crossword Game
            </h3>
            <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
              <div className="text-black font-semibold">
                User: <span className="text-black0 font-bold">{name}</span>{" "}
              </div>
              <span className="mx-2">|</span>
              <div className="text-black font-semibold">
                Timer: <span className="text-red-500 font-bold">{counter}</span>{" "}
              </div>
            </div>
          </header>

          <div className="flex justify-center items-center flex-grow w-full p-5">
            <div className="container grid grid-cols-4 gap-2 md:gap-3 p-3 md:grid-cols-4">
              {cards.map((card, index) => {
                return (
                  <Card
                    key={index}
                    card={card}
                    index={index}
                    isDisabled={shouldDisableAllCards}
                    isInactive={checkIsInactive(card)}
                    isFlipped={checkIsFlipped(index)}
                    onClick={handleCardClick}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}

      {/* FINISH */}
      {state === STATE.FINISH && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardFinish
            name={name}
            onChangeState={(val) => handleChangeState(val)}
            score={totalScore}
          />
        </div>
      )}

      {/* LEADERBOARD */}
      {state === STATE.LEADERBOARD && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardLeaderboard onChangeState={(val) => handleChangeState(val)} />
        </div>
      )}
    </div>
  );
};

export default GameMatchImage;
