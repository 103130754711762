import { useEffect, useState } from "react";
import STATE from "../../../constanta/state";
import SelectInput from "../../../components/input/select.input";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "../../../components/input/text.input";
import axios from "axios";
import Cookies from "js-cookie";
import CONFIG from "../../../constanta/config";

const CardStart = (props) => {
  const [loading, setLoading] = useState(false);
  const [listTerritory, setListTerritory] = useState([]);
  const [loadingTerritoy, setLoadingTerritoy] = useState(false);
  const [listUser, setListUser] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [name, setName] = useState("");
  const [msgError, setMsgError] = useState("");

  const validationSchema = Yup.object().shape({
    user: Yup.object()
      .shape({
        first_name: Yup.string().required("Name is required"),
      })
      .nullable()
      .required("Name is required."),
    employee_id: Yup.string().required("Employee ID is required"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    getTerritory();
  }, []);

  const getTerritory = () => {
    setLoadingTerritoy(true);
    axios
      .get(CONFIG.URL + "/user/territory?rnd=" + Date.now())
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          setListTerritory(response.data.data);
          //setValue("territory", response.data.data[0]);
        } else {
        }
        setLoadingTerritoy(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoadingTerritoy(false);
      });
  };

  const getUsers = (slug) => {
    setLoadingUser(true);
    axios
      .get(CONFIG.URL + "/user/name/" + slug + "?rnd=" + Date.now())
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          setListUser(response.data.data);
        } else {
        }
        setLoadingUser(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoadingUser(false);
      });
  };

  useEffect(() => {
    if (watch("territory")) {
      setListUser([]);
      setValue("user", null);
      getUsers(watch("territory").slug);
    }
  }, [watch("territory")]);

  const filterUsers = (option, searchText) => {
    if (
      option?.data?.first_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      option?.data?.last_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      option?.data?.departement
        ?.toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (data) => {
    if (loading) return;

    setMsgError("");
    setLoading(true);

    const payload = {
      id_user: data.user.id,
      employee_id: data.employee_id,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/login?rnd=" + Date.now(), form_data)
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          props.handleLogin(response.data);
        } else {
          setMsgError(
            `ERROR! The Employee ID entered does not match our records for ${
              watch("user").first_name
            } ${watch("user").last_name}. Please Try Again!`
          );
        }
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        setLoading(false);
        setMsgError(
          `ERROR! Something wrong with the system please try again or inform this case to admin.`
        );
      });
  };

  return (
    <div className="w-full px-0 flex justify-center items-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="rounded-lg w-full md:h-max flex flex-col justify-between items-start px-5 md:w-2/3 bg-[#ffffffd9] border-[4px] py-2 md:py-4"
      >
        <div className="flex flex-col justify-center items-center w-full">
          <div>
            <div className="mb-2 text-xl text-center font-bold text-black">
              Welcome
            </div>
            <div className="font-semibold">{props?.config?.welcome}</div>
          </div>

          <div className="w-full flex flex-col justify-start items-start mt-4">
            <SelectInput
              label="Territory:"
              register={register}
              name="territory"
              placeholder="Select Territory"
              isInvalid={errors.territory}
              isRequired
              control={control}
              options={listTerritory}
              getOptionValue={(option) => option.slug}
              isLoading={loadingTerritoy}
            />

            <SelectInput
              label="Name:"
              register={register}
              name="user"
              placeholder="Start typing your name and select from the dropdown list."
              isInvalid={errors.user}
              isRequired
              control={control}
              options={listUser}
              getOptionLabel={(option) => {
                return (
                  <>
                    {option.first_name} {option.last_name} -{" "}
                    {option.departement}
                  </>
                );
              }}
              getOptionValue={(option) => option.slug}
              filterOption={filterUsers}
              isLoading={loadingUser}
            />

            <TextInput
              label="Employee ID"
              register={register}
              name="employee_id"
              placeholder="Type your Employee ID"
              isInvalid={errors.employee_id}
              isRequired
            />
          </div>
          {msgError !== "" && (
            <div className="flex justify-center items-center bg-red-400 mt-2 rounded-lg py-2 text-white w-full font-bold">
              <span>{msgError}</span>
            </div>
          )}
        </div>

        <div className="flex mt-4 justify-between w-full space-x-2">
          <button
            onClick={() => props.onBack()}
            type="button"
            className=" flex-none w-24 font-bold cursor-pointer flex justify-center items-center text-base text-white h-[45px] px-2 bg-[#c52f7ad4] hover:bg-[#c52f7ade] rounded-lg "
          >
            BACK
          </button>
          <button className="font-bold cursor-pointer w-full flex justify-center items-center text-base text-white h-[45px] px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg ">
            {loading ? "Loading..." : "SUBMIT"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CardStart;
