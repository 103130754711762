import { useState } from "react";
import STATE from "../../../constanta/state";

const CardStart = (props) => {
  const [name, setName] = useState("");
  const [msgError, setMsgError] = useState("");

  const updateInputValue = (evt) => {
    const val = evt.target.value;
    setName(val);
    props.setName(val);
  };

  const playGame = () => {
    setMsgError("");

    if (name == "") {
      setMsgError("Please input your name.");
    } else {
      props.onChangeState(STATE.GAME);
    }
  };

  return (
    <div className="w-full px-4 flex justify-center items-center">
      <div className="rounded-lg w-full md:h-max flex flex-col justify-between items-center px-5 md:w-1/3 bg-[#ffffffd9] border-[4px]  py-5">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="my-4 text-xl text-center font-bold text-black">
            Scramble Word Game
          </div>

          <div className="w-full flex flex-col justify-start items-start">
            <label className="font-semibold">Name:</label>
            <input
              value={name}
              onChange={(evt) => updateInputValue(evt)}
              className="border rounded-lg text-lg text-black py-4 px-5 w-full focus:outline-none focus:border-blue-900 focus:border-2"
            ></input>
          </div>
          {msgError != "" && (
            <div className="flex justify-center items-center bg-red-400 mt-2 rounded-lg py-2 text-white w-full font-bold">
              <span>{msgError}</span>
            </div>
          )}
        </div>

        <div className="flex flex-col justify-center items-center my-5">
          <div className="font-bold">Instructions:</div>
          <div className="font-semibold">Text instruction here</div>
        </div>
        <div className="flex mt-4 justify-between w-full">
          <div
            onClick={() => playGame()}
            className="font-bold cursor-pointer w-full flex justify-center items-center text-lg text-white py-4 px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
          >
            PLAY GAME
          </div>
          {/* <div
            onClick={() => props.onChangeState(STATE.LEADERBOARD)}
            className="ml-2 flex flex-none w-[60px] h-[60px] font-bold cursor-pointer justify-center items-center text-lg text-white py-4 px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
          >
            <img
              className="h-full object-contain"
              src={"https://eventqiu.com/assets/leaderboard.png"}
              alt=""
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CardStart;
