import axios from "axios";
import CONFIG from "../../../constanta/config";
import { useState } from "react";
import parse from "html-react-parser";

const CardIntro = (props) => {
  const { user, game, activity } = props;
  const [loading, setLoading] = useState();

  const postScore = async () => {
    if(props.score !== ""){
      props.handleBegin();
    }

    if (loading) {
      return;
    }

    setLoading(true);

    const payload = {
      id_activity: activity?.id,
      duration: 0,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/score?rnd=" + Date.now(), form_data, {
        headers: {
          token: user?.token,
        },
      })
      .then(function (response) {
        setLoading(false);
        if (response.data.status === "SUCCESS") {
          props.handleBegin();
        } else {
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  };

  const renderInstruction = (text) => {
    let str = text?.replaceAll("\r\n", "<br/>") || ""
    console.log(str)
    return str;
  }

  return (
    <div className="w-full px-0 flex justify-center items-center">
      <div className="rounded-lg w-full md:h-max flex flex-col justify-between items-start px-5 md:w-2/3 bg-[#ffffffd9] border-[4px] py-2 md:py-4">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="w-full">
            <div className="mb-2 text-xl text-center font-bold text-black">
              Welcome
            </div>
            <div className="mb-2 text-4xl text-center font-bold text-black flex flex-col md:flex-row w-full justify-center items-center">
              <span>
                {user?.first_name} {user?.last_name}
              </span>
              <span className="hidden md:block mx-3"> -</span>{" "}
              <span className="text-gray-500 text-xl md:text-4xl">
                {user?.departement}
              </span>
            </div>

            <div className="w-full">
              <div className="mt-8 text-xl font-bold text-black text-left">
                Instruction:
              </div>
              <div className="font-semibold mt-2 text-justify">
                {parse(renderInstruction(game?.instruction || "") || "")}
              </div>
              <div className="uppercase bg-yellow-300 py-2  font-bold text-red-600 text-xs mt-2">
                {props?.config?.warning}
              </div>
            </div>
          </div>
          <div className="flex mt-4 justify-between w-full space-x-2">
            <button
              onClick={() => {
                if (loading) {
                  return;
                }

                props.onBack();
              }}
              type="button"
              className=" flex-none w-24 font-bold cursor-pointer flex justify-center items-center text-base text-white h-[45px] px-2 bg-[#c52f7ad4] hover:bg-[#c52f7ade] rounded-lg "
            >
              BACK
            </button>
            <button
              onClick={() => postScore()}
              className="font-bold cursor-pointer max-w-max flex justify-center items-center text-base text-white h-[45px] px-10 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
            >
              {
                loading ? 'Loading...' : 'BEGIN'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardIntro;
