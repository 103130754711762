const CardFinish = (props) => {
  return (
    <>
      <div className="rounded-lg w-full  md:h-max flex flex-col justify-between items-center px-5 md:w-1/2 bg-[#ffffffd9] border-[4px]  py-5">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="text-2xl text-center font-bold text-gray-700">
            {props?.user?.first_name} {props?.user?.last_name}
          </div>
          <div className="mb-4 text-2xl text-center font-bold text-black">
            CONGRATULATIONS!
          </div>
        </div>
        <div className="flex flex-col justify-center items-center w-full">
          <div className="text-lg text-center text-black">
            You have completed
          </div>
          <div className="text-xl text-center font-bold text-black">
            {props?.game?.name}
          </div>
        </div>
        <div className="my-5 flex flex-col justify-center items-center w-full">
          <div className="text-xl text-center font-bold text-black">
            YOUR SCORE
          </div>

          <div className="text-6xl text-center font-bold text-black  w-full">
            <div className="flex w-full justify-center items-center w-full">
              <div className="flex flex-col w-[80px]">
                <span>
                  {("0" + Math.floor((props?.duration / 60000) % 60)).slice(-2)}
                </span>
                <span className="text-base font-normal">minute</span>
              </div>
              <div className="flex flex-col">
                <span>:</span> <span className="text-base">&nbsp;</span>
              </div>
              <div className="flex flex-col w-[80px]">
                <span>
                  {("0" + Math.floor((props?.duration / 1000) % 60)).slice(-2)}
                </span>
                <span className="text-base font-normal">seconds</span>
              </div>
              <div className="flex flex-col">
                <span>.</span> <span className="text-base">&nbsp;</span>
              </div>
              <div className="flex flex-col w-[80px]">
                <span>{("00" + ((props?.duration / 1) % 1000)).slice(-3)}</span>
                <span className="text-base font-normal">millisecond</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex mt-4 justify-between w-full">
          <a
            href="/RBLCPL2023"
            className="font-bold cursor-pointer w-full flex justify-center items-center text-lg text-white py-4 px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
          >
            FINISH
          </a>
        </div>
      </div>
    </>
  );
};

export default CardFinish;
