import { useEffect, useState } from "react";

const Stopwatch = (props) => {
  const [time, setTime] = useState(0);
  useEffect(() => {
    let interval;
    if (props.playing) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
        props.setDuration((prevTime) => prevTime + 10);
      }, 10);
    } else if (!props.playing) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [props.playing]);
  return (
    <div className="stopwatch text-white text-3xl">
      <div className="flex">
        <div className="w-[50px]">
          {("0" + Math.floor((time / 60000) % 60)).slice(-2)}
        </div>
        <span>:</span>
        <div className="w-[50px]">
          {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
        </div>
        <span>.</span>
        <div className="w-[50px]">{("00" + ((time / 1) % 1000)).slice(-3)}</div>
      </div>

      {/* <div className="buttons">
          <button onClick={() => setRunning(true)}>Start</button>
          <button onClick={() => setRunning(false)}>Stop</button>
          <button onClick={() => setTime(0)}>Reset</button>       
        </div> */}
    </div>
  );
};

export default Stopwatch;
