import { useState, useMemo, useEffect } from "react";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "../cross-word/components/cardLeaderboard";
import ALPHABET from "../../constanta/alphabet";

export default function GameScrambleWord() {
  const [state, setState] = useState(STATE.START);
  const [name, setName] = useState("");
  const [counter, setCounter] = useState(0);
  const [boxAnswer, setBoxAnswer] = useState([]);
  const [boxOption, setBoxOption] = useState([]);
  const [no, setNo] = useState(0);
  const [boxNo, setBoxNo] = useState(0);
  const [wrong, setWrong] = useState(false);
  const [w, setW] = useState(0);
  // useEffect(() => {
  //   if (state === STATE.GAME) {
  //     const timer =
  //       counter > 0 && setInterval(() => setCounter(counter + 1), 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [state, counter]);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const questions = [
    {
      image: "https://eventqiu.com/assets/scramble/img_1.png?v=1",
      answer: "alligator",
    },
    {
      image: "https://eventqiu.com/assets/scramble/img_2.png?v=1",
      answer: "bird",
    },
    {
      image: "https://eventqiu.com/assets/scramble/img_3.png?v=1",
      answer: "buffalo",
    },
  ];

  useEffect(() => {
    setUpQuestion();
  }, [no]);

  const handleChangeState = (val) => {
    setState(val);
  };

  const setUpQuestion = () => {
    setBoxNo(0);

    let char = [...questions[no].answer.split("")];
    let answer = char.map((item) => {
      return {
        char: "",
        idOption: "",
      };
    });

    setBoxAnswer(answer);

    // let rndOption = [];
    // let sufAlphabet = [...shuffle(ALPHABET)];
    // let i = 0;
    // while (i < 5) {
    //   rndOption.push(sufAlphabet[i]);
    //   i++;
    // }

    let charOption = [...questions[no]?.answer.split("")];
    let option = shuffle(charOption).map((item, key) => {
      return {
        char: item,
        active: false,
        idOption: key,
      };
    });
    setBoxOption([...option]);
  };

  const clickBoxNo = (index, idOption, val) => {
    setWrong(false);
    if (val === "") {
      setBoxNo(index);
      return;
    }

    setBoxNo(index);
    let temp = [...boxAnswer];
    temp[index].char = "";
    temp[index].idOption = "";
    setBoxAnswer(temp);

    let tempOption = [...boxOption];
    let option = tempOption.find((item) => item.idOption === idOption);
    option.active = false;
    setBoxOption(tempOption);
  };

  const chooseLetter = (val, idOption) => {
    setWrong(false);
    let temp = [...boxAnswer];
    let tempOption = [...boxOption];

    if (temp[boxNo].char !== "") {
      let optionExist = tempOption.find(
        (item) => item.idOption === temp[boxNo].idOption
      );
      optionExist.active = false;
    }

    temp[boxNo].char = val;
    temp[boxNo].idOption = idOption;
    setBoxAnswer(temp);

    if (boxNo < boxAnswer.length - 1) {
      setBoxNo(boxNo + 1);
    }

    let option = tempOption.find((item) => item.idOption === idOption);
    option.active = true;
    setBoxOption(tempOption);
  };

  const checkAnswer = () => {
    setWrong(false);
    let rightAnswer = questions[no].answer;
    let userAnswer = boxAnswer.map((item) => item.char).join("");

    if (rightAnswer.toLowerCase() === userAnswer.toLocaleLowerCase()) {
      if (no === questions.length - 1) {
        handleChangeState(STATE.FINISH);
      } else {
        setNo(no + 1);
        setUpQuestion();
      }
    } else {
      setWrong(true);
    }
  };

  useEffect(() => {
    let w = parseInt(window.innerWidth * (70/100));
    w = w/boxAnswer.length
    if(w > 60){
      w = 60
    }
    setW(parseInt(w));
  }, [boxAnswer])

  return (
    <div
      className="App flex justify-start items-start flex-col min-h-screen"
      style={{
        background:
          "url('https://eventqiu.com/assets/bg_game_dark.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
      }}
    >
      {/* START */}
      {state === STATE.START && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardStart
            onChangeState={(val) => handleChangeState(val)}
            setName={(val) => setName(val)}
          />
        </div>
      )}

      {/* GAME */}
      {state === STATE.GAME && (
        <>
          <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-none justify-between items-center w-full h-14">
            <h3 className="text-xl d:text-3xl font-bold text white w-full text-left">
              Scramble Word Game
            </h3>
            <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
              <div className="text-black font-semibold">
                User: <span className="text-black0 font-bold">{name}</span>{" "}
              </div>
              <span className="mx-2">|</span>
              <div className="text-black font-semibold">
                Timer: <span className="text-red-500 font-bold">{counter}</span>{" "}
              </div>
            </div>
          </header>

          <div className="flex flex-col space-y-5 justify-center items-center flex-grow w-full p-2 md:p-5">
            <div className="container relative p-3 ">
              <div className="flex justify-center flex-wrap font-semibold md:text-xl break-words px-3 w-[300px] md:w-full">
                <span className="mr-1">The category is</span>{" "}
                <strong>Animals</strong>.
              </div>
            </div>
            <div className="flex flex-wrap w-full md:max-w-[60vw] justify-center items-center">
              {boxAnswer?.map((item, key) => {
                return (
                  <div
                    onClick={() => clickBoxNo(key, item.idOption, item.char)}
                    key={key}
                    className={`${
                      wrong
                        ? "bg-red-300"
                        : key === boxNo
                        ? "bg-[#42ade2d4]"
                        : "bg-[#ffffffd9]"
                    } mx-1 mb-1 cursor-pointer border-[2px] ${
                      wrong ? "border-red-500" : "border-white"
                    } rounded-[6px] md:rounded-[16px] relative flex justify-center items-center`}
                      style={{
                        height: w,
                        width: w
                      }}
                    >
                    <div className="font-bold md:text-2xl uppercase">
                      {item.char}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="container relative p-2 md:p-3 md:w-full">
              <div className=" flex justify-center flex-wrap font-semibold md:text-xl mb-3 break-words px-3 w-[300px] md:w-full">
                Click on the letters below to make the word that describes the
                category
              </div>
              <div className="flex flex-wrap items-center justify-center w-[300px] md:w-full">
                {boxOption?.map((item, key) => {
                  return (
                    <div
                      onClick={() => {
                        if (!item.active) {
                          chooseLetter(item.char, item.idOption);
                        }
                      }}
                      key={key}
                      className={`cursor-pointer ${
                        item.active ? "bg-yellow-600" : "bg-yellow-300"
                      } hover:bg-yellow-600 mx-1 mb-1 border-[2px] border-yellow-700 rounded-[6px] md:rounded-[16px] w-[40px] h-[40px] relative md:w-[50px] md:h-[50px] flex justify-center items-center`}
                    >
                      <div className="font-bold md:text-2xl uppercase">
                        {item.char}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              onClick={() => checkAnswer()}
              className="font-bold cursor-pointer max-w-max flex justify-center items-center text-lg text-white py-2 px-10 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
            >
              CHECK
            </div>
          </div>
        </>
      )}

      {/* FINISH */}
      {state === STATE.FINISH && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardFinish
            name={name}
            onChangeState={(val) => handleChangeState(val)}
            score={0}
          />
        </div>
      )}

      {/* LEADERBOARD */}
      {state === STATE.LEADERBOARD && (
        <>
          <CardLeaderboard onChangeState={(val) => handleChangeState(val)} />
        </>
      )}
    </div>
  );
}
