import { useState, useMemo, useEffect } from "react";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "../cross-word/components/cardLeaderboard";
import ALPHABET from "../../constanta/alphabet";
import CountdownTimer from "../../components/countdownTimer";
import Stopwatch from "../../components/stopwatch";
import axios from "axios";
import CONFIG from "../../constanta/config";
import CardDiskualifikasi from "../../components/cardDiskualifikasi";

export default function LiveGameScrambleWord(props) {
  const { user, game, idActivity, config } = props;
  const [state, setState] = useState();
  const [finish, setFinish] = useState(false);
  const [duration, setDuration] = useState(0);
  const [boxAnswer, setBoxAnswer] = useState([]);
  const [boxOption, setBoxOption] = useState([]);
  const [no, setNo] = useState(0);
  const [totalCorrect, setTotalCorrect] = useState(0);
  const [boxNo, setBoxNo] = useState(0);
  const [wrong, setWrong] = useState(false);
  const [correct, setCorrect] = useState(false);
  const [w, setW] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [gameSetting, setGameSetting] = useState(null);

  useEffect(() => {
    const handler = (event) => {
      if (state !== STATE.FINISH && state !== STATE.FAIL) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [state]);

  useEffect(() => {
    if (props.score !== "" && props.score !== "0") {
      setDuration(props.score);
      setState(STATE.FINISH);
    } else if (props.score === "0") {
      setState(STATE.FAIL);
    } else {
      setState(STATE.START);
    }
  }, [props.score]);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  useEffect(() => {
    if (game) {
      let setting = JSON.parse(game.game_setting);
      setGameSetting(setting);
      let words = setting.words.map((item) => {
        return {
          answer: item.toLowerCase(),
        };
      });

      setQuestions(shuffle(words));
    }
  }, [game]);

  useEffect(() => {
    if (questions.length > 0) {
      setUpQuestion();
    }
  }, [questions]);

  useEffect(() => {
    if (no !== 0) {
      setUpQuestion();
    }
  }, [no]);

  const handleChangeState = (val) => {
    setState(val);
  };

  const setUpQuestion = () => {
    setBoxNo(0);

    let char = [...questions[no]?.answer.split("")];
    let answer = char.map((item) => {
      return {
        char: "",
        idOption: "",
      };
    });

    setBoxAnswer(answer);

    // let rndOption = [];
    // let sufAlphabet = [...shuffle(ALPHABET)];
    // let i = 0;
    // while (i < 5) {
    //   rndOption.push(sufAlphabet[i]);
    //   i++;
    // }

    let charOption = [...questions[no]?.answer.split("")];
    let option = shuffle(charOption).map((item, key) => {
      return {
        char: item,
        active: false,
        idOption: key,
      };
    });
    setBoxOption([...option]);
  };

  const clickBoxNo = (index, idOption, val) => {
    setWrong(false);
    setCorrect(false);

    if (val === "") {
      setBoxNo(index);
      return;
    }

    setBoxNo(index);
    let temp = [...boxAnswer];
    temp[index].char = "";
    temp[index].idOption = "";
    setBoxAnswer(temp);

    let tempOption = [...boxOption];
    let option = tempOption.find((item) => item.idOption === idOption);
    option.active = false;
    setBoxOption(tempOption);
  };

  const resetBox = () => {
    setWrong(false);
    setCorrect(false);
    let tempBox = [...boxAnswer];
    tempBox = tempBox.map((item) => {
      return {
        ...item,
        char: "",
        idOption: ""
      };
    });

    setBoxAnswer(tempBox);

    let tempOption = [...boxOption]
    tempOption = tempOption.map((item) => {
      return {
        ...item,
        active: false,
      };
    });

    setBoxOption(tempOption);
    setBoxNo(0)

  };

  const chooseLetter = (val, idOption) => {
    setWrong(false);
    setCorrect(false);
    let temp = [...boxAnswer];
    let tempOption = [...boxOption];

    if (temp[boxNo].char !== "") {
      let optionExist = tempOption.find(
        (item) => item.idOption === temp[boxNo].idOption
      );
      optionExist.active = false;
    }

    temp[boxNo].char = val;
    temp[boxNo].idOption = idOption;
    setBoxAnswer(temp);

    if (boxNo < boxAnswer.length - 1) {
      setBoxNo(boxNo + 1);
    }

    let option = tempOption.find((item) => item.idOption === idOption);
    option.active = true;
    setBoxOption(tempOption);
  };

  const checkAnswer = () => {
    setWrong(false);
    setCorrect(false);
    let rightAnswer = questions[no]?.answer;
    let userAnswer = boxAnswer.map((item) => item.char).join("");

    if (rightAnswer.toLowerCase() === userAnswer.toLocaleLowerCase()) {
      if (totalCorrect === gameSetting.max_puzzle - 1) {
        setFinish(true);
        setCorrect(true);
        postScore();
      } else {
        setCorrect(true);
        setTimeout(() => {
          setCorrect(false);
          setTotalCorrect(totalCorrect + 1);
          if (no === questions.length - 1) {
            setNo(0);
          } else {
            setNo(no + 1);
          }

          setUpQuestion();
        }, 500);
      }
    } else {
      setWrong(true);

      setTimeout(() => {
        resetBox();
      }, 200);

      setCorrect(false);
    }
  };

  const skip = () => {
    setWrong(false);

    if (no === questions.length - 1) {
      setNo(0);
    } else {
      setNo(no + 1);
    }

    setUpQuestion();
  };

  const postScore = async () => {
    const payload = {
      id_activity: idActivity,
      duration: duration,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/score?rnd=" + Date.now(), form_data, {
        headers: {
          token: user?.token,
        },
      })
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          handleChangeState(STATE.FINISH);
        } else {
        }
        setFinish(false);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    let w = parseInt(window.innerWidth * (70 / 100));
    w = w / boxAnswer.length;
    if (w > 60) {
      w = 60;
    }
    setW(parseInt(w));
  }, [boxAnswer]);

  return (
    <>
      <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-col md:flex-row flex-none justify-between items-center w-full h-auto py-2 md:py-0 md:h-14">
        <h3 className="text-xl md:text-2xl font-bold text white w-full text-center md:text-left">
          {game?.name}
        </h3>
        <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
          <div className="text-black font-semibold">
            <span className="text-black-0 font-bold text-xl md:text-2xl">
              {user?.first_name} {user?.last_name} -{" "}
              <span className="text-gray-500">{user?.departement}</span>
            </span>{" "}
          </div>
        </div>
      </header>

      {finish && (
        <div className="fixed z-50 h-screen w-screen bg-[#000000b3] text-white flex justify-center items-center font-semibold text-lg">
          Please wait to submit score...
        </div>
      )}

      {state === STATE.START && (
        <CountdownTimer onComplete={() => setState(STATE.GAME)} />
      )}

      {state === STATE.GAME && gameSetting !== null && (
        <div className="flex flex-col justify-center items-center flex-grow w-full md:p-5">
          <div className="text-white font-semibold text-xl mb-2">
            {totalCorrect} / {gameSetting.max_puzzle}
          </div>
          <div className="flex items-end space-x-3 text-white font-semibold text-xl mb-2">
            <span>Time:</span>
            <Stopwatch
              playing={state === STATE.GAME && !finish}
              setDuration={(val) => setDuration(val)}
            />
          </div>
          <div className="container relative p-3 w-max">
            <div className="flex justify-center items-center flex-wrap font-semibold md:text-xl break-words px-3 w-full md:w-full">
              {gameSetting?.text_category}
            </div>
          </div>
          <div className="flex flex-wrap w-full md:max-w-[60vw] justify-center items-center my-5">
            {boxAnswer?.map((item, key) => {
              return (
                <div
                  onClick={() => clickBoxNo(key, item.idOption, item.char)}
                  key={key}
                  className={`${
                    correct
                      ? "bg-green-400"
                      : wrong
                      ? "bg-red-300"
                      : key === boxNo
                      ? "bg-[#42ade2d4]"
                      : "bg-[#ffffffd9]"
                  }

                  
                  
                  mx-1 mb-1 cursor-pointer border-[2px] 
                  
                  ${
                    correct
                      ? "border-green-400"
                      : wrong
                      ? "border-red-500"
                      : "border-white"
                  } rounded-[6px] md:rounded-[16px] relative flex justify-center items-center`}
                  style={{
                    height: w,
                    width: w,
                  }}
                >
                  <div className="font-bold md:text-2xl uppercase">
                    {item.char}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="container relative p-2 md:p-3 w-max">
            <div className=" flex justify-center flex-wrap font-semibold md:text-xl mb-3 break-words px-3 w-[95vw] md:w-full">
              {gameSetting?.text_info}
            </div>
            <div className="flex flex-wrap items-center justify-center w-[95vw] md:w-full">
              {boxOption?.map((item, key) => {
                return (
                  <div
                    onClick={() => {
                      if (!item.active) {
                        chooseLetter(item.char, item.idOption);
                      }
                    }}
                    key={key}
                    className={`cursor-pointer ${
                      item.active ? "bg-yellow-600" : "bg-yellow-300"
                    } hover:bg-yellow-600 mx-1 mb-1 border-[2px] border-yellow-700 rounded-[6px] md:rounded-[16px] w-[40px] h-[40px] relative md:w-[50px] md:h-[50px] flex justify-center items-center`}
                  >
                    <div className="font-bold md:text-2xl uppercase">
                      {item.char}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex space-x-10">
            <button
              onClick={() => skip()}
              type="button"
              className="mt-5 font-bold cursor-pointer max-w-max flex justify-center items-center text-lg text-white py-2 px-10 bg-[#c52f7ad4] hover:bg-[#c52f7ade] rounded-lg "
            >
              SKIP
            </button>
            <div
              onClick={() => checkAnswer()}
              className="mt-5 font-bold cursor-pointer max-w-max flex justify-center items-center text-lg text-white py-2 px-10 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
            >
              CHECK
            </div>
          </div>
        </div>
      )}

      {state === STATE.FINISH && (
        <div className="flex flex-col justify-center items-center flex-grow w-full p-5">
          <CardFinish duration={duration} user={user} game={game} />
        </div>
      )}

      {state === STATE.FAIL && (
        <div className="flex flex-col justify-center items-center flex-grow w-full p-5">
          <CardDiskualifikasi
            duration={duration}
            user={user}
            game={game}
            config={config}
          />
        </div>
      )}
    </>
  );
}
