import { useEffect } from "react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const selectStyles = {
  control: (styles) => ({
    ...styles,
    height: "45px",
    textAlign: "left",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    color: "#333333",
    backgroundColor: "white",
    borderColor: "#EAECEE",
    borderRadius: "10px",
    borderWidth: "2px",
    width: "100%",
    position: "relative",
    outline: "none",
    boxShadow: "none",
    "&:hover": {
      borderColor: "none",
    },
  }),
  input: (styles) => ({
    ...styles,
    color: "#333333",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    textAlign: "left",
    color: "#333333",
    backgroundColor: !isDisabled
      ? isSelected
        ? "#EAECEE"
        : "white"
      : undefined,
    "&:hover": {
      borderColor: "none",
      backgroundColor: "#EAECEE",
    },
  }),
};

const selectErrorStyles = {
  control: (styles) => ({
    ...styles,
    height: "45px",
    textAlign: "left",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    color: "#333333",
    backgroundColor: "white",
    borderColor: "#CB000F",
    borderRadius: "10px",
    borderWidth: "2px",
    width: "100%",
    position: "relative",
    outline: "none",
    boxShadow: "none",
    "&:hover": {
      borderColor: "none",
    },
  }),
  input: (styles) => ({
    ...styles,
    color: "#333333",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    textAlign: "left",
    color: "#333333",
    backgroundColor: !isDisabled
      ? isSelected
        ? "#EAECEE"
        : "white"
      : undefined,
    "&:hover": {
      borderColor: "none",
      backgroundColor: "#EAECEE",
    },
  }),
};

const SelectInput = ({
  name,
  label,
  placeholder,
  isRequired,
  isInvalid,
  control,
  color,
  otherInfo,
  actionOtherInfo,
  options,
  nomargin = false,
  getOptionLabel = (option) => option.name,
  getOptionValue = (option) => option.value,
  filterOption = null,
  isLoading = false,
}) => {
  const [focus, setFocus] = useState(false);

  const customFilter = (option, searchText) => {
    if (
      option?.data.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      option?.data.value?.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };


  return (
    <>
      <div
        className={`${
          isInvalid ? "mb-3" : nomargin ? "mb-0" : "mb-3"
        } flex flex-col w-full`}
      >
        {label && (
          <div className="flex justify-between">
            <label className={`text-sm ${color ? color : "text-black-1"}`}>
              {label}
            </label>

            {otherInfo && (
              <label
                onClick={() => actionOtherInfo()}
                className={`cursor-pointer text-xs text-[#0944A1] underline`}
              >
                {otherInfo}
              </label>
            )}
          </div>
        )}
        <div className="relative w-full">
          <Controller
            name={name}
            control={control}
            rules={{ required: isRequired }}
            render={({ field }) => (
              <Select
                {...field}
                options={options}
                styles={isInvalid ? selectErrorStyles : selectStyles}
                placeholder={placeholder}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                filterOption={
                  filterOption !== null ? filterOption : customFilter
                }
                isLoading={isLoading}
              />
            )}
          />

          {isInvalid && (
            <div className="text-[#CB000F] text-sm font-semibold mt-1 flex items-end w-full  bg-red-1 ">
              {isInvalid.message || isInvalid["first_name"].message}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectInput;
