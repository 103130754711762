import axios from "axios";
import { useEffect, useState } from "react";

const CardLeaderboard = (props) => {
  useEffect(() => {
    getLeaderboard();
  }, []);

  const [leaderboard, setLeaderboard] = useState([]);

  const getLeaderboard = () => {
    axios
      .get(
        "https://eventqiu.jagoan.info/api/leaderboard/2?limit=100000&order_name=score&order_dir=desc"
      )
      .then(function (response) {
        setLeaderboard(response.data.data);
      })
      .catch(function (error) {})
      .finally(function () {});
  };

  return (
    <>
      <div className="rounded-lg w-full  md:max-h-[90%] flex flex-col justify-between items-center px-5 md:w-2/3 bg-[#ffffffd9] border-[4px]  py-5">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="my-4 text-3xl text-center font-bold text-black">
            LEADERBOARD
          </div>
        </div>

        <div className="flex justify-between items-center w-full bg-orange-600 py-2 px-3 rounded-lg text-white">
          <span className=" flex flex-none justify-center items-center w-[50px] text-center text-lg font-bold">
            No
          </span>
          <span className="text-center w-1/2 text-lg font-bold">Name</span>
          <span className="text-center  w-1/2  text-lg font-bold">Score</span>
        </div>
        <div className="w-full mt-5 flex flex-col justify-start items-start max-h-[100%] overflow-y-auto">
          <div className="flex flex-col space-y-2 w-full">
            {leaderboard?.map((item, key) => {
              return (
                <div
                  key={key}
                  className="flex justify-between items-center w-full bg-[#82491087] py-2 px-3 rounded-lg text-white"
                >
                  <span className=" flex flex-none justify-center items-center w-[50px] text-center text-lg font-bold">
                    {key + 1}.
                  </span>
                  <span className="text-center w-1/2 text-lg font-bold">
                    {item.name}
                  </span>
                  <span className="text-center  w-1/2  text-lg font-bold">
                    {item.score}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex mt-4 justify-between w-full space-x-4">
          <div
            onClick={() => window.location.reload(false)}
            className="font-bold cursor-pointer w-full flex justify-center items-center text-lg text-white py-4 px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
          >
            BACK
          </div>
        </div>
      </div>
    </>
  );
};

export default CardLeaderboard;
