import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";

import GameMatchImage from "./game/match-image/matchImage.game";
import GameMaze from "./game/maze/maze.game";
import GameCrossWord from "./game/cross-word/crossWord.game";
import GameScrambleWord from "./game/scramble-word/scrambleWord.game";
import GameHangman from "./game/hangman/hangman.game";
import SampleCanvas from "./game/sample-canvas/sampleCanvas";
import GameSearchWord from "./game/search-word/search.game";
import Home from "./pages/home/home";
import ListGame from "./pages/listGame";
import Campaign from "./pages/campaign/campaign";
import HomeLB from "./pages/home/home-lb";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/leaderboard" element={<HomeLB />} />
      <Route path="/memory" element={<GameMatchImage />} />
      <Route path="/maze" element={<GameMaze />} />
      <Route path="/crossword" element={<GameCrossWord />} />
      <Route path="/scramble-word" element={<GameScrambleWord />} />
      <Route path="/hangman" element={<GameHangman />} />
      <Route path="/search-word" element={<GameSearchWord />} />
      <Route path="/game" element={<ListGame />} />
    </Routes>
  );
}

export default App;
