import { useState, useMemo, useEffect } from "react";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "../cross-word/components/cardLeaderboard";
import ALPHABET from "../../constanta/alphabet";
import imgCross from "../../assets/icon_cross.png";
import axios from "axios";

export default function GameHangman() {
  const [state, setState] = useState(STATE.START);
  const [name, setName] = useState("");
  const [counter, setCounter] = useState(0);
  const [boxAnswer, setBoxAnswer] = useState([]);
  const [boxOption, setBoxOption] = useState([]);
  const [no, setNo] = useState(0);
  const [currLive, setCurrLive] = useState(7);
  const [live, setLive] = useState([]);
  const [canCheck, setCheck] = useState(false);
  // useEffect(() => {
  //   if (state === STATE.GAME) {
  //     const timer =
  //       counter > 0 && setInterval(() => setCounter(counter + 1), 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [state, counter]);

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const questions = [
    {
      answer: "indiana jones",
    },
    {
      answer: "game of thrones",
    },
    {
      answer: "avatar",
    },
  ];

  useEffect(() => {
    setUpQuestion();
  }, [no]);

  const handleChangeState = (val) => {
    setState(val);
  };

  const setUpQuestion = () => {
    let char = [...questions[no].answer.split("")];
    let answer = char.map((item) => {
      return {
        char: "",
        guide: item,
        show: false,
      };
    });

    setBoxAnswer(answer);

    let option = ALPHABET.map((item, key) => {
      return {
        char: item,
        active: false,
        idOption: key,
      };
    });
    setBoxOption([...option]);

    let _live = [];
    let i = 0;
    while (i < currLive) {
      _live.push({
        active: true,
      });
      i++;
    }

    setLive(_live);
  };

  const chooseLetter = (val, idOption) => {
    setCheck(true);
    let tempOption = [...boxOption];
    let option = tempOption.find((item) => item.idOption === idOption);
    option.active = true;
    setBoxOption(tempOption);

    let char = [...questions[no].answer.split("")];
    let arrIdx = [];
    let i = 0;
    while (i < char.length) {
      if (val.toLowerCase() === char[i]) {
        arrIdx.push(i);
      }
      i++;
    }

    if (arrIdx.length > 0) {
      let temp = [...boxAnswer];
      let j = 0;
      while (j < arrIdx.length) {
        temp[arrIdx[j]].char = val;
        temp[arrIdx[j]].show = true;
        j++;
      }

      setBoxAnswer(temp);
    } else {
      let leftLive = currLive - 1;
      if (leftLive === 0) {
        handleChangeState(STATE.FINISH);
      } else {
        let _live = [...live];
        _live[leftLive].active = false;
        setLive(_live);
        setCurrLive(leftLive);
      }
    }
  };

  useEffect(() => {
    if (canCheck) {
      let showNotValid = boxAnswer.find(
        (item) => item.show === false && item.guide !== " "
      );

      if (!showNotValid) {
        if (no === questions.length - 1) {
          handleChangeState(STATE.FINISH);
        } else {
          setCurrLive(7);
          setNo(no + 1);
          setUpQuestion();
        }
      }
    }
  }, [boxAnswer]);

  return (
    <div
      className="App flex justify-start items-start flex-col min-h-screen"
      style={{
        background:
          "url('https://eventqiu.com/assets/bg_game_dark.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
      }}
    >
      {/* START */}
      {state === STATE.START && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardStart
            onChangeState={(val) => handleChangeState(val)}
            setName={(val) => setName(val)}
          />
        </div>
      )}

      {/* GAME */}
      {state === STATE.GAME && (
        <>
          <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-none justify-between items-center w-full h-14">
            <h3 className="text-xl d:text-3xl font-bold text white w-full text-left">
              Hangman Game
            </h3>
            <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
              <div className="text-black font-semibold">
                User: <span className="text-black0 font-bold">{name}</span>{" "}
              </div>
              <span className="mx-2">|</span>
              <div className="text-black font-semibold">
                Timer: <span className="text-red-500 font-bold">{counter}</span>{" "}
              </div>
            </div>
          </header>

          <div className="flex flex-col space-y-5 justify-center items-center flex-grow w-full p-2 md:p-5">
            <div className="flex space-x-2">
              {live?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="relative bg-white rounded-md border-2 border-[#42ade2d4]"
                  >
                    <img
                      src={"https://eventqiu.com/assets/CPL_Tile.jpg"}
                      className={`w-14 object-contain`}
                      w={70}
                      h={70}
                      alt=""
                    />

                    {!item.active && (
                      <img
                        src={imgCross}
                        className={`p-1 absolute top-0 left-0 w-14 object-contain`}
                        w={70}
                        h={70}
                        alt=""
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="container relative p-3 ">
              <div className="flex justify-center flex-wrap font-semibold md:text-xl break-words px-3 w-[300px] md:w-full">
                <span className="mr-1">Find the hidden word. The topic is</span>{" "}
                <strong>Movie</strong>.
              </div>
            </div>
            <div className="flex flex-wrap w-full justify-center items-center">
              {boxAnswer?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className={`mb-2 ${
                      item.guide === " "
                        ? "opacity-0 w-full h-0 md:w-[50px] md:h-[60px]"
                        : " w-[30px] h-[40px] md:w-[50px] md:h-[60px]"
                    }   text-white mx-1 mb-1  border-b-[4px]  relative   flex justify-center items-center`}
                    style={{ textShadow: "2px 1px 2px rgba(0, 0, 0, 0.88)" }}
                  >
                    <div className="font-bold md:text-4xl uppercase">
                      {item.char}
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="container relative p-2 md:p-3 md:w-full">
              <div className="flex flex-wrap items-center justify-center w-[300px] md:w-[800px]">
                {boxOption?.map((item, key) => {
                  return (
                    <div
                      onClick={() => {
                        if (!item.active) {
                          chooseLetter(item.char, item.idOption);
                        }
                      }}
                      key={key}
                      className={`cursor-pointer ${
                        item.active ? "bg-yellow-600" : "bg-yellow-300"
                      } hover:bg-yellow-600 mx-1 mb-1 border-[2px] border-yellow-700 rounded-[6px] md:rounded-[16px] w-[40px] h-[40px] relative md:w-[50px] md:h-[50px] flex justify-center items-center`}
                    >
                      <div className="font-bold md:text-2xl uppercase">
                        {item.char}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}

      {/* FINISH */}
      {state === STATE.FINISH && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardFinish
            name={name}
            onChangeState={(val) => handleChangeState(val)}
            score={0}
          />
        </div>
      )}

      {/* LEADERBOARD */}
      {state === STATE.LEADERBOARD && (
        <>
          <CardLeaderboard onChangeState={(val) => handleChangeState(val)} />
        </>
      )}
    </div>
  );
}
