const CardDiskualifikasi = (props) => {
  return (
    <>
      <div className="rounded-lg w-full  md:h-max flex flex-col justify-between items-center px-5 md:w-1/2 bg-[#ffffffd9] border-[4px]  py-5">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="text-2xl text-center font-bold text-gray-700">
            {props?.user?.first_name} {props?.user?.last_name}
          </div>
          <div className="uppercase mb-4 text-2xl text-center font-bold text-black">
            {props?.config?.disqualified}
          </div>
        </div>

        <div className="flex mt-4 justify-between w-full">
          <a
            href="/RBLCPL2023"
            className="font-bold cursor-pointer w-full flex justify-center items-center text-lg text-white py-4 px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
          >
            BACK
          </a>
        </div>
      </div>
    </>
  );
};

export default CardDiskualifikasi;
