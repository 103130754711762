const STATE = {
  START: "START",
  INTRO: "INTRO",
  WARNING: "WARNING",
  GAME: "GAME",
  FINISH: "FINISH",
  LEADERBOARD: "LEADERBOARD",
  CAMPAIGN: "CAMPAIGN",
  FAIL: "FAIL"
};

export default STATE;
