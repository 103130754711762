import { useState, useMemo, useEffect, useRef } from "react";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "../cross-word/components/cardLeaderboard";
import ALPHABET from "../../constanta/alphabet";
import imgCross from "../../assets/icon_cross.png";
import { isMobile } from "react-device-detect";

export default function GameSearchWord() {
  const [state, setState] = useState(STATE.START);
  const [name, setName] = useState("");
  const [counter, setCounter] = useState(0);
  const [gridBox, setGridBox] = useState([]);

  // useEffect(() => {
  //   if (state === STATE.GAME) {
  //     const timer =
  //       counter > 0 && setInterval(() => setCounter(counter + 1), 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [state, counter]);

  const handleChangeState = (val) => {
    setState(val);
  };

  /**
   * Returns a random integer between min and max
   *
   * @param {Number} min
   * @param {Number} max
   * @return {Number}
   */
  if (typeof Math.rangeInt != "function") {
    Math.rangeInt = function (min, max) {
      if (max == undefined) {
        max = min;
        min = 0;
      }
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };
  }

  /**
   * Mege two objects
   *
   * @param {Object} o1 Object 1
   * @param {Object} o2 Object 2
   * @return {Object}
   */
  if (typeof Object.merge != "function") {
    Object.merge = function (o1, o2) {
      for (var i in o1) {
        o2[i] = o1[i];
      }
      return o2;
    };
  }

  (function () {
    "use strict";

    // Extend the element method
    Element.prototype.wordSearch = function (settings) {
      return new WordSearch(this, settings);
    };

    /**
     * Word seach
     *
     * @param {Element} wrapWl the game's wrap element
     * @param {Array} settings
     * constructor
     */
    function WordSearch(wrapEl, settings) {
      this.wrapEl = wrapEl;

      // Add `.ws-area` to wrap element
      this.wrapEl.classList.add("ws-area");

      //Words solved.
      this.solved = 0;

      // Default settings
      var default_settings = {
        directions: ["W", "N", "WN", "EN"],
        gridSize: 8,
        words: ["one", "two", "three", "four", "five"],
        wordsList: [],
        debug: false,
      };
      this.settings = Object.merge(settings, default_settings);

      // Check the words' length if it is overflow the grid
      if (this.parseWords(this.settings.gridSize)) {
        // Add words into the matrix data
        var isWorked = false;

        while (isWorked == false) {
          // initialize the application
          this.initialize();

          isWorked = this.addWords();
        }

        // Fill up the remaining blank items
        if (!this.settings.debug) {
          this.fillUpFools();
        }

        // Draw the matrix into wrap element
        this.drawmatrix();
      }
    }

    /**
     * Parse words
     * @param {Number} Max size
     * @return {Boolean}
     */
    WordSearch.prototype.parseWords = function (maxSize) {
      var itWorked = true;

      for (var i = 0; i < this.settings.words.length; i++) {
        // Convert all the letters to upper case
        this.settings.wordsList[i] = this.settings.words[i].trim();
        this.settings.words[i] = removeDiacritics(
          this.settings.wordsList[i].trim().toUpperCase()
        );

        var word = this.settings.words[i];
        if (word.length > maxSize) {
          alert("The length of word `" + word + "` is overflow the gridSize.");
          console.error(
            "The length of word `" + word + "` is overflow the gridSize."
          );
          itWorked = false;
        }
      }

      return itWorked;
    };

    /**
     * Put the words into the matrix
     */
    WordSearch.prototype.addWords = function () {
      var keepGoing = true,
        counter = 0,
        isWorked = true;

      while (keepGoing) {
        // Getting random direction
        var dir =
            this.settings.directions[
              Math.rangeInt(this.settings.directions.length - 1)
            ],
          result = this.addWord(this.settings.words[counter], dir),
          isWorked = true;

        if (result == false) {
          keepGoing = false;
          isWorked = false;
        }

        counter++;
        if (counter >= this.settings.words.length) {
          keepGoing = false;
        }
      }

      return isWorked;
    };

    /**
     * Add word into the matrix
     *
     * @param {String} word
     * @param {Number} direction
     */
    WordSearch.prototype.addWord = function (word, direction) {
      var itWorked = true,
        directions = {
          W: [0, 1], // Horizontal (From left to right)
          N: [1, 0], // Vertical (From top to bottom)
          WN: [1, 1], // From top left to bottom right
          EN: [1, -1], // From top right to bottom left
        },
        row,
        col; // y, x

      switch (direction) {
        case "W": // Horizontal (From left to right)
          var row = Math.rangeInt(this.settings.gridSize - 1),
            col = Math.rangeInt(this.settings.gridSize - word.length);
          break;

        case "N": // Vertical (From top to bottom)
          var row = Math.rangeInt(this.settings.gridSize - word.length),
            col = Math.rangeInt(this.settings.gridSize - 1);
          break;

        case "WN": // From top left to bottom right
          var row = Math.rangeInt(this.settings.gridSize - word.length),
            col = Math.rangeInt(this.settings.gridSize - word.length);
          break;

        case "EN": // From top right to bottom left
          var row = Math.rangeInt(this.settings.gridSize - word.length),
            col = Math.rangeInt(word.length - 1, this.settings.gridSize - 1);
          break;

        default:
          var error = "UNKNOWN DIRECTION " + direction + "!";
          alert(error);
          console.log(error);
          break;
      }

      // Add words to the matrix
      for (var i = 0; i < word.length; i++) {
        var newRow = row + i * directions[direction][0],
          newCol = col + i * directions[direction][1];

        // The letter on the board
        var origin = this.matrix[newRow][newCol].letter;

        if (origin == "." || origin == word[i]) {
          this.matrix[newRow][newCol].letter = word[i];
        } else {
          itWorked = false;
        }
      }

      return itWorked;
    };

    /**
     * Initialize the application
     */
    WordSearch.prototype.initialize = function () {
      /**
       * Letter matrix
       *
       * param {Array}
       */
      this.matrix = [];

      /**
       * Selection from
       * @Param {Object}
       */
      this.selectFrom = null;

      /**
       * Selected items
       */
      this.selected = [];

      this.initmatrix(this.settings.gridSize);
    };

    /**
     * Fill default items into the matrix
     * @param {Number} size Grid size
     */
    WordSearch.prototype.initmatrix = function (size) {
      for (var row = 0; row < size; row++) {
        for (var col = 0; col < size; col++) {
          var item = {
            letter: ".", // Default value
            row: row,
            col: col,
          };

          if (!this.matrix[row]) {
            this.matrix[row] = [];
          }

          this.matrix[row][col] = item;
        }
      }
    };

    /**
     * Draw the matrix
     */
    WordSearch.prototype.drawmatrix = function () {
      for (var row = 0; row < this.settings.gridSize; row++) {
        // New row
        var divEl = document.createElement("div");
        divEl.setAttribute("class", "ws-row");
        this.wrapEl.appendChild(divEl);

        for (var col = 0; col < this.settings.gridSize; col++) {
          var cvEl = document.createElement("canvas");
          cvEl.setAttribute("class", "ws-col");
          cvEl.setAttribute("width", 40);
          cvEl.setAttribute("height", 40);

          // Fill text in middle center
          var x = cvEl.width / 2,
            y = cvEl.height / 2;

          var ctx = cvEl.getContext("2d");
          ctx.font = "400 28px Calibri";
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "#333"; // Text color
          ctx.fillText(this.matrix[row][col].letter, x, y);

          // Add event listeners
          if (!isMobile) {
            cvEl.addEventListener(
              "mousedown",
              this.onMousedown(this.matrix[row][col])
            );
            cvEl.addEventListener(
              "mouseover",
              this.onMouseover(this.matrix[row][col])
            );
            cvEl.addEventListener("mouseup", this.onMouseup());
          } else {
            cvEl.addEventListener(
              "touchstart",
              this.onMousedownMobile(this.matrix[row][col])
            );
            cvEl.addEventListener(
              "touchstart",
              this.onMouseoverMobile(this.matrix[row][col])
            );
            // cvEl.addEventListener("touchend", this.onMouseup());
          }

          divEl.appendChild(cvEl);
        }
      }
    };

    /**
     * Fill up the remaining items
     */
    WordSearch.prototype.fillUpFools = function () {
      var rangeLanguage = searchLanguage(this.settings.words[0].split("")[0]);
      for (var row = 0; row < this.settings.gridSize; row++) {
        for (var col = 0; col < this.settings.gridSize; col++) {
          if (this.matrix[row][col].letter == ".") {
            // Math.rangeInt(65, 90) => A ~ Z
            this.matrix[row][col].letter = String.fromCharCode(
              Math.rangeInt(rangeLanguage[0], rangeLanguage[1])
            );
          }
        }
      }
    };

    /**
     * Returns matrix items
     * @param rowFrom
     * @param colFrom
     * @param rowTo
     * @param colTo
     * @return {Array}
     */
    WordSearch.prototype.getItems = function (rowFrom, colFrom, rowTo, colTo) {
      var items = [];

      if (
        rowFrom === rowTo ||
        colFrom === colTo ||
        Math.abs(rowTo - rowFrom) == Math.abs(colTo - colFrom)
      ) {
        var shiftY = rowFrom === rowTo ? 0 : rowTo > rowFrom ? 1 : -1,
          shiftX = colFrom === colTo ? 0 : colTo > colFrom ? 1 : -1,
          row = rowFrom,
          col = colFrom;

        items.push(this.getItem(row, col));
        do {
          row += shiftY;
          col += shiftX;
          items.push(this.getItem(row, col));
        } while (row !== rowTo || col !== colTo);
      }

      return items;
    };

    /**
     * Returns matrix item
     * @param {Number} row
     * @param {Number} col
     * @return {*}
     */
    WordSearch.prototype.getItem = function (row, col) {
      return this.matrix[row] ? this.matrix[row][col] : undefined;
    };

    /**
     * Clear the exist highlights
     */
    WordSearch.prototype.clearHighlight = function () {
      var selectedEls = document.querySelectorAll(".ws-selected");
      for (var i = 0; i < selectedEls.length; i++) {
        selectedEls[i].classList.remove("ws-selected");
      }
    };

    /**
     * Lookup if the wordlist contains the selected
     * @param {Array} selected
     */
    var background = [
      "bg-blue-300",
      "bg-green-300",
      "bg-yellow-300",
      "bg-purple-300",
      "bg-orange-300",
      "bg-pink-300",
      "bg-amber-300",
    ];
    var noBg = 0;
    WordSearch.prototype.lookup = function (selected) {
      var words = [""];

      for (var i = 0; i < selected.length; i++) {
        words[0] += selected[i].letter;
      }
      words.push(words[0].split("").reverse().join(""));

      if (
        this.settings.words.indexOf(words[0]) > -1 ||
        this.settings.words.indexOf(words[1]) > -1
      ) {
        if (noBg >= background.length) {
          noBg = 0;
        }
        for (var i = 0; i < selected.length; i++) {
          var row = selected[i].row + 1,
            col = selected[i].col + 1,
            el = document.querySelector(
              ".ws-area .ws-row:nth-child(" +
                row +
                ") .ws-col:nth-child(" +
                col +
                ")"
            );

          // el.classList.add("ws-found");

          el.classList.add(background[noBg]);
        }
        noBg++;

        //Cross word off list.
        var wordList = document.querySelector(".ws-words");
        var wordListItems = wordList.getElementsByTagName("li");
        for (var i = 0; i < wordListItems.length; i++) {
          if (
            words[0] ==
            removeDiacritics(wordListItems[i].innerHTML.toUpperCase())
          ) {
            if (
              wordListItems[i].innerHTML !=
              "<del>" + wordListItems[i].innerHTML + "</del>"
            ) {
              //Check the word is never found
              wordListItems[i].innerHTML =
                "<del>" + wordListItems[i].innerHTML + "</del>";
              //Increment solved words.
              this.solved++;
            }
          }
        }

        //Game over?
        if (this.solved == this.settings.words.length) {
          handleChangeState(STATE.FINISH);
          // this.gameOver();
        }
      }
    };

    /**
     * Game Over
     */
    WordSearch.prototype.gameOver = function () {
      //Create overlay.
      var overlay = document.createElement("div");
      overlay.setAttribute("id", "ws-game-over-outer");
      overlay.setAttribute("class", "ws-game-over-outer");
      this.wrapEl.parentNode.appendChild(overlay);

      //Create overlay content.
      var overlay = document.getElementById("ws-game-over-outer");
      overlay.innerHTML =
        "<div class='ws-game-over-inner' id='ws-game-over-inner'>" +
        "<div class='ws-game-over' id='ws-game-over'>" +
        "<h2>Congratulations!</h2>" +
        "<p>You've found all of the words!</p>" +
        "</div>" +
        "</div>";
    };

    /**
     * Mouse event - Mouse down
     * @param {Object} item
     */
    WordSearch.prototype.onMousedown = function (item) {
      var _this = this;
      return function () {
        _this.selectFrom = item;
      };
    };

    /**
     * Mouse event - Mouse move
     * @param {Object}
     */
    WordSearch.prototype.onMouseover = function (item) {
      var _this = this;
      return function () {
        if (_this.selectFrom) {
          _this.selected = _this.getItems(
            _this.selectFrom.row,
            _this.selectFrom.col,
            item.row,
            item.col
          );

          _this.clearHighlight();

          for (var i = 0; i < _this.selected.length; i++) {
            var current = _this.selected[i],
              row = current.row + 1,
              col = current.col + 1,
              el = document.querySelector(
                ".ws-area .ws-row:nth-child(" +
                  row +
                  ") .ws-col:nth-child(" +
                  col +
                  ")"
              );

            el.className += " ws-selected";
          }
        }
      };
    };

    /**
     * Mouse event - Mouse up
     */
    WordSearch.prototype.onMouseup = function () {
      var _this = this;
      return function () {
        _this.selectFrom = null;
        _this.clearHighlight();
        _this.lookup(_this.selected);
        _this.selected = [];
      };
    };

    /**
     * Mouse event - Mouse down
     * @param {Object} item
     */
    WordSearch.prototype.onMousedownMobile = function (item) {
      var _this = this;
      return function () {
        if (!_this.selectFrom) {
          _this.selectFrom = item;
          console.log(_this.selectFrom);
        } else {
          if (_this.selectFrom === item) {
            _this.selectFrom = null;
            _this.clearHighlight();
            _this.lookup(_this.selected);
            _this.selected = [];
          }
        }
      };
    };

    /**
     * Mouse event - Mouse move
     * @param {Object}
     */
    WordSearch.prototype.onMouseoverMobile = function (item) {
      var _this = this;
      return function () {
        if (_this.selectFrom) {
          _this.selected = _this.getItems(
            _this.selectFrom.row,
            _this.selectFrom.col,
            item.row,
            item.col
          );


          

          _this.clearHighlight();

          if (_this.selected.length === 0) {
            _this.selectFrom = null;
            _this.clearHighlight();
            _this.lookup(_this.selected);
            _this.selected = [];
          } else {
            for (var i = 0; i < _this.selected.length; i++) {
              var current = _this.selected[i],
                row = current.row + 1,
                col = current.col + 1,
                el = document.querySelector(
                  ".ws-area .ws-row:nth-child(" +
                    row +
                    ") .ws-col:nth-child(" +
                    col +
                    ")"
                );

              el.className += " ws-selected";
            }

            console.log( _this.selected)

            if (
              _this.selected.length > 2 ||
              ((`${_this.selected[0].row}_${_this.selected[0].col}`) !== (`${_this.selected[1].row}_${_this.selected[1].col}`))
            ) {
              _this.selectFrom = null;
              _this.clearHighlight();
              _this.lookup(_this.selected);
              _this.selected = [];
            }
          }
        }
      };
    };

    /**
     * Mouse event - Mouse up
     */
    WordSearch.prototype.onMouseupMobile = function () {
      var _this = this;
      return function () {
        _this.selectFrom = null;
        _this.clearHighlight();
        _this.lookup(_this.selected);
        _this.selected = [];
      };
    };
  })();
  //-----------------------------Remove accent for latin/hebrew letters---------------------------------------------------//
  var defaultDiacriticsRemovalMap = [
    {
      base: "A",
      letters:
        /(&#65;|&#9398;|&#65313;|&#192;|&#193;|&#194;|&#7846;|&#7844;|&#7850;|&#7848;|&#195;|&#256;|&#258;|&#7856;|&#7854;|&#7860;|&#7858;|&#550;|&#480;|&#196;|&#478;|&#7842;|&#197;|&#506;|&#461;|&#512;|&#514;|&#7840;|&#7852;|&#7862;|&#7680;|&#260;|&#570;|&#11375;|[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F])/g,
    },
    {
      base: "AA",
      letters: /(&#42802;|[\uA732])/g,
    },
    {
      base: "AE",
      letters: /(&#198;|&#508;|&#482;|[\u00C6\u01FC\u01E2])/g,
    },
    {
      base: "AO",
      letters: /(&#42804;|[\uA734])/g,
    },
    {
      base: "AU",
      letters: /(&#42806;|[\uA736])/g,
    },
    {
      base: "AV",
      letters: /(&#42808;|&#42810;|[\uA738\uA73A])/g,
    },
    {
      base: "AY",
      letters: /(&#42812;|[\uA73C])/g,
    },
    {
      base: "B",
      letters:
        /(&#66;|&#9399;|&#65314;|&#7682;|&#7684;|&#7686;|&#579;|&#386;|&#385;|[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181])/g,
    },
    {
      base: "C",
      letters:
        /(&#67;|&#9400;|&#65315;|&#262;|&#264;|&#266;|&#268;|&#199;|&#7688;|&#391;|&#571;|&#42814;|[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E])/g,
    },
    {
      base: "D",
      letters:
        /(&#68;|&#9401;|&#65316;|&#7690;|&#270;|&#7692;|&#7696;|&#7698;|&#7694;|&#272;|&#395;|&#394;|&#393;|&#42873;|&#208;|[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0])/g,
    },
    {
      base: "DZ",
      letters: /(&#497;|&#452;|[\u01F1\u01C4])/g,
    },
    {
      base: "Dz",
      letters: /(&#498;|&#453;|[\u01F2\u01C5])/g,
    },
    {
      base: "E",
      letters:
        /(&#69;|&#9402;|&#65317;|&#200;|&#201;|&#202;|&#7872;|&#7870;|&#7876;|&#7874;|&#7868;|&#274;|&#7700;|&#7702;|&#276;|&#278;|&#203;|&#7866;|&#282;|&#516;|&#518;|&#7864;|&#7878;|&#552;|&#7708;|&#280;|&#7704;|&#7706;|&#400;|&#398;|[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E])/g,
    },
    {
      base: "F",
      letters:
        /(&#70;|&#9403;|&#65318;|&#7710;|&#401;|&#42875;|[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B])/g,
    },
    {
      base: "G",
      letters:
        /(&#71;|&#9404;|&#65319;|&#500;|&#284;|&#7712;|&#286;|&#288;|&#486;|&#290;|&#484;|&#403;|&#42912;|&#42877;|&#42878;|[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E])/g,
    },
    {
      base: "H",
      letters:
        /(&#72;|&#9405;|&#65320;|&#292;|&#7714;|&#7718;|&#542;|&#7716;|&#7720;|&#7722;|&#294;|&#11367;|&#11381;|&#42893;|[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D])/g,
    },
    {
      base: "I",
      letters:
        /(&#73;|&#9406;|&#65321;|&#204;|&#205;|&#206;|&#296;|&#298;|&#300;|&#304;|&#207;|&#7726;|&#7880;|&#463;|&#520;|&#522;|&#7882;|&#302;|&#7724;|&#407;|[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197])/g,
    },
    {
      base: "J",
      letters:
        /(&#74;|&#9407;|&#65322;|&#308;|&#584;|[\u004A\u24BF\uFF2A\u0134\u0248])/g,
    },
    {
      base: "K",
      letters:
        /(&#75;|&#9408;|&#65323;|&#7728;|&#488;|&#7730;|&#310;|&#7732;|&#408;|&#11369;|&#42816;|&#42818;|&#42820;|&#42914;|[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2])/g,
    },
    {
      base: "L",
      letters:
        /(&#76;|&#9409;|&#65324;|&#319;|&#313;|&#317;|&#7734;|&#7736;|&#315;|&#7740;|&#7738;|&#321;|&#573;|&#11362;|&#11360;|&#42824;|&#42822;|&#42880;|[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780])/g,
    },
    {
      base: "LJ",
      letters: /(&#455;|[\u01C7])/g,
    },
    {
      base: "Lj",
      letters: /(&#456;|[\u01C8])/g,
    },
    {
      base: "M",
      letters:
        /(&#77;|&#9410;|&#65325;|&#7742;|&#7744;|&#7746;|&#11374;|&#412;|[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C])/g,
    },
    {
      base: "N",
      letters:
        /(&#78;|&#9411;|&#65326;|&#504;|&#323;|&#209;|&#7748;|&#327;|&#7750;|&#325;|&#7754;|&#7752;|&#544;|&#413;|&#42896;|&#42916;|&#330;|[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4\u014A])/g,
    },
    {
      base: "NJ",
      letters: /(&#458;|[\u01CA])/g,
    },
    {
      base: "Nj",
      letters: /(&#459;|[\u01CB])/g,
    },
    {
      base: "O",
      letters:
        /(&#79;|&#9412;|&#65327;|&#210;|&#211;|&#212;|&#7890;|&#7888;|&#7894;|&#7892;|&#213;|&#7756;|&#556;|&#7758;|&#332;|&#7760;|&#7762;|&#334;|&#558;|&#560;|&#214;|&#554;|&#7886;|&#336;|&#465;|&#524;|&#526;|&#416;|&#7900;|&#7898;|&#7904;|&#7902;|&#7906;|&#7884;|&#7896;|&#490;|&#492;|&#216;|&#510;|&#390;|&#415;|&#42826;|&#42828;|[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C])/g,
    },
    {
      base: "OE",
      letters: /(&#338;|[\u0152])/g,
    },
    {
      base: "OI",
      letters: /(&#418;|[\u01A2])/g,
    },
    {
      base: "OO",
      letters: /(&#42830;|[\uA74E])/g,
    },
    {
      base: "OU",
      letters: /(&#546;|[\u0222])/g,
    },
    {
      base: "P",
      letters:
        /(&#80;|&#9413;|&#65328;|&#7764;|&#7766;|&#420;|&#11363;|&#42832;|&#42834;|&#42836;|[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754])/g,
    },
    {
      base: "Q",
      letters:
        /(&#81;|&#9414;|&#65329;|&#42838;|&#42840;|&#586;|[\u0051\u24C6\uFF31\uA756\uA758\u024A])/g,
    },
    {
      base: "R",
      letters:
        /(&#82;|&#9415;|&#65330;|&#340;|&#7768;|&#344;|&#528;|&#530;|&#7770;|&#7772;|&#342;|&#7774;|&#588;|&#11364;|&#42842;|&#42918;|&#42882;|[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782])/g,
    },
    {
      base: "S",
      letters:
        /(&#83;|&#9416;|&#65331;|&#7838;|&#346;|&#7780;|&#348;|&#7776;|&#352;|&#7782;|&#7778;|&#7784;|&#536;|&#350;|&#11390;|&#42920;|&#42884;|[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784])/g,
    },
    {
      base: "T",
      letters:
        /(&#84;|&#9417;|&#65332;|&#7786;|&#356;|&#7788;|&#538;|&#354;|&#7792;|&#7790;|&#358;|&#428;|&#430;|&#574;|&#42886;|[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786])/g,
    },
    {
      base: "TH",
      letters: /(&#222;|[\u00DE])/g,
    },
    {
      base: "TZ",
      letters: /(&#42792;|[\uA728])/g,
    },
    {
      base: "U",
      letters:
        /(&#85;|&#9418;|&#65333;|&#217;|&#218;|&#219;|&#360;|&#7800;|&#362;|&#7802;|&#364;|&#220;|&#475;|&#471;|&#469;|&#473;|&#7910;|&#366;|&#368;|&#467;|&#532;|&#534;|&#431;|&#7914;|&#7912;|&#7918;|&#7916;|&#7920;|&#7908;|&#7794;|&#370;|&#7798;|&#7796;|&#580;|[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244])/g,
    },
    {
      base: "V",
      letters:
        /(&#86;|&#9419;|&#65334;|&#7804;|&#7806;|&#434;|&#42846;|&#581;|[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245])/g,
    },
    {
      base: "VY",
      letters: /(&#42848;|[\uA760])/g,
    },
    {
      base: "W",
      letters:
        /(&#87;|&#9420;|&#65335;|&#7808;|&#7810;|&#372;|&#7814;|&#7812;|&#7816;|&#11378;|[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72])/g,
    },
    {
      base: "X",
      letters:
        /(&#88;|&#9421;|&#65336;|&#7818;|&#7820;|[\u0058\u24CD\uFF38\u1E8A\u1E8C])/g,
    },
    {
      base: "Y",
      letters:
        /(&#89;|&#9422;|&#65337;|&#7922;|&#221;|&#374;|&#7928;|&#562;|&#7822;|&#376;|&#7926;|&#7924;|&#435;|&#590;|&#7934;|[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE])/g,
    },
    {
      base: "Z",
      letters:
        /(&#90;|&#9423;|&#65338;|&#377;|&#7824;|&#379;|&#381;|&#7826;|&#7828;|&#437;|&#548;|&#11391;|&#11371;|&#42850;|[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762])/g,
    },
    {
      base: "", //delete Niqqud in Hebrew
      letters: /[\u0591-\u05C7]/g,
    },
  ];

  function removeDiacritics(str) {
    for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
      str = str.replace(
        defaultDiacriticsRemovalMap[i].letters,
        defaultDiacriticsRemovalMap[i].base
      );
    }
    return str;
  }
  //------------------------------Search language--------------------------------------------------//
  // Determine what letters injected on grid
  function searchLanguage(firstLetter) {
    let codefirstLetter = firstLetter.charCodeAt();
    var codeLetter = [65, 90];
    if (codefirstLetter >= 65 && codefirstLetter <= 90) {
      // Latin
      return (codeLetter = [65, 90]);
    }
    if (codefirstLetter >= 1488 && codefirstLetter <= 1514) {
      //Hebrew א -> ת
      return (codeLetter = [1488, 1514]);
    }
    if (codefirstLetter >= 913 && codefirstLetter <= 937) {
      //Greek Α -> Ω
      return (codeLetter = [913, 929]); //930 is blank
    }
    if (codefirstLetter >= 1040 && codefirstLetter <= 1071) {
      //Cyrillic А -> Я
      return (codeLetter = [1040, 1071]); //930 is blank
    }
    if (codefirstLetter >= 1569 && codefirstLetter <= 1610) {
      //Arab
      return (codeLetter = [1569, 1594]); //Between 1595 and 1600, no letter
    }
    if (codefirstLetter >= 19969 && codefirstLetter <= 40891) {
      //Chinese
      return (codeLetter = [19969, 40891]);
    }
    if (codefirstLetter >= 12354 && codefirstLetter <= 12436) {
      //Japan Hiragana
      return (codeLetter = [12388, 12418]); //Only no small letter
    }
    console.log("Letter not detected : " + firstLetter + ":" + codefirstLetter);
    return codeLetter;
  }

  const cont = useRef(null);

  useEffect(() => {
    if (state === STATE.GAME && cont.current !== null) {
      var gameAreaEl = document.getElementById("ws-area");
      var gameobj = gameAreaEl.wordSearch();

      // Put words into `.ws-words`
      var words = gameobj.settings.wordsList,
        wordsWrap = document.querySelector(".ws-words");
      for (let i in words) {
        var liEl = document.createElement("li");
        liEl.setAttribute("class", "ws-word");
        liEl.innerText = words[i];
        wordsWrap.appendChild(liEl);
      }
    }
  }, [cont.current, state]);

  return (
    <div
      className="App flex justify-start items-start flex-col min-h-screen"
      style={{
        background:
          "url('https://eventqiu.com/assets/bg_game_dark.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
      }}
    >
      {/* START */}
      {state === STATE.START && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardStart
            onChangeState={(val) => handleChangeState(val)}
            setName={(val) => setName(val)}
          />
        </div>
      )}

      {/* GAME */}
      {state === STATE.GAME && (
        <>
          <header className=" bg-[#ffffffd9] px-3 md:px-5 flex flex-none justify-between items-center w-full h-14">
            <h3 className="text-xl d:text-3xl font-bold text white w-full text-left">
              Search Word Game
            </h3>
            <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
              <div className="text-black font-semibold">
                User: <span className="text-black0 font-bold">{name}</span>{" "}
              </div>
              <span className="mx-2">|</span>
              <div className="text-black font-semibold">
                Timer: <span className="text-red-500 font-bold">{counter}</span>{" "}
              </div>
            </div>
          </header>

          <div className="flex flex-col space-y-5 justify-center items-center flex-grow w-full p-2 md:p-5">
            <div className="container relative p-2 md:p-3 md:w-full">
              <div
                ref={cont}
                class="wrap flex flex-col md:flex-row md:space-x-5 w-full md:w-full"
              >
                <section id="ws-area" className="md:p-5"></section>
                <div className="flex flex-col justify-start items-start mt-5 md:mt-0">
                  <div className="font-bold mb-2">Find The Word</div>
                  <ul class="ws-words"></ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* FINISH */}
      {state === STATE.FINISH && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardFinish
            name={name}
            onChangeState={(val) => handleChangeState(val)}
            score={0}
          />
        </div>
      )}

      {/* LEADERBOARD */}
      {state === STATE.LEADERBOARD && (
        <>
          <CardLeaderboard onChangeState={(val) => handleChangeState(val)} />
        </>
      )}
    </div>
  );
}
