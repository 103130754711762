import { useEffect, useState, useRef } from "react";
import Card from "./card";
import CardStart from "./components/cardStart";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "./components/cardLeaderboard";
import axios from "axios";
import STATE from "../../constanta/state";
import CountdownTimer from "../../components/countdownTimer";
import GameTimer from "../../components/gameTimer";
import Stopwatch from "../../components/stopwatch";
import { isMobile } from "react-device-detect";
import CONFIG from "../../constanta/config";
import CardDiskualifikasi from "../../components/cardDiskualifikasi";

function shuffleCards(array) {
  const length = array.length;
  for (let i = length; i > 0; i--) {
    const randomIndex = Math.floor(Math.random() * i);
    const currentIndex = i - 1;
    const temp = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

const LiveGameMatchImage = (props) => {
  const { user, game, idActivity, config } = props;
  const [state, setState] = useState();
  const [finish, setFinish] = useState(false);
  const [duration, setDuration] = useState(0);
  const [sizeCard, setSizeCard] = useState(0);
  const [wBox, setWBox] = useState(0);
  const [gameSetting, setGameSetting] = useState(null);

  const [cards, setCards] = useState([]);
  const [openCards, setOpenCards] = useState([]);
  const [clearedCards, setClearedCards] = useState({});
  const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
  const timeout = useRef(null);

  useEffect(() => {
    const handler = (event) => {
      if (state !== STATE.FINISH && state !== STATE.FAIL) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [state]);

  useEffect(() => {
    if (props.score !== "" && props.score !== "0") {
      setDuration(props.score);
      setState(STATE.FINISH);
    } else if (props.score === "0") {
      setState(STATE.FAIL);
    } else {
      setState(STATE.START);
    }
  }, [props.score]);

  const disable = () => {
    setShouldDisableAllCards(true);
  };
  const enable = () => {
    setShouldDisableAllCards(false);
  };

  const checkCompletion = () => {
    if (Object.keys(clearedCards).length === gameSetting?.images?.length) {
      setFinish(true);
      postScore();
    }
  };

  const postScore = async () => {
    const payload = {
      id_activity: idActivity,
      duration: duration,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    axios
      .post(CONFIG.URL + "/score?rnd=" + Date.now(), form_data, {
        headers: {
          token: user?.token,
        },
      })
      .then(function (response) {
        if (response.data.status === "SUCCESS") {
          setState(STATE.FINISH);
        } else {
        }
        setFinish(false);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    if (game) {
      let setting = JSON.parse(game.game_setting);
      setGameSetting(setting);

      let arr = setting.images.map((item, index) => {
        return {
          type: (index + 1).toString(),
          image: item,
        };
      });

      setCards(shuffleCards.bind(null, arr.concat(arr)));

      if (isMobile) {
        let h = window.innerWidth;
        let boxH = h * 0.95;
        setWBox(boxH);
        setSizeCard(boxH / setting.grid - 2);
      } else {
        let w = window.innerHeight;
        let boW = w * 0.8;
        setWBox(boW);
        setSizeCard(boW / setting.grid - 2);
      }
    }
  }, [game]);

  const evaluate = () => {
    const [first, second] = openCards;
    enable();
    if (cards[first].type === cards[second].type) {
      setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
      setOpenCards([]);
      return;
    }
    // This is to flip the cards back after 500ms duration
    timeout.current = setTimeout(() => {
      setOpenCards([]);
    }, 500);
  };
  const handleCardClick = (index) => {
    if (openCards.length === 1) {
      setOpenCards((prev) => [...prev, index]);
      disable();
    } else {
      clearTimeout(timeout.current);
      setOpenCards([index]);
    }
  };

  useEffect(() => {
    let timeout = null;
    if (openCards.length === 2) {
      timeout = setTimeout(evaluate, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [openCards]);

  useEffect(() => {
    checkCompletion();
  }, [clearedCards]);
  const checkIsFlipped = (index) => {
    return openCards.includes(index);
  };

  const checkIsInactive = (card) => {
    return Boolean(clearedCards[card.type]);
  };

  return (
    <>
      <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-col md:flex-row flex-none justify-between items-center w-full h-auto py-2 md:py-0 md:h-14">
        <h3 className="text-xl md:text-2xl font-bold text white w-full text-center md:text-left">
          {game?.name}
        </h3>
        <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
          <div className="text-black font-semibold">
            <span className="text-black-0 font-bold text-xl md:text-2xl">
              {user?.first_name} {user?.last_name} -{" "}
              <span className="text-gray-500">{user?.departement}</span>
            </span>{" "}
          </div>
        </div>
      </header>

      {finish && (
        <div className="fixed z-50 h-screen w-screen bg-[#000000b3] text-white flex justify-center items-center font-semibold text-lg">
          Please wait to submit score...
        </div>
      )}

      {state === STATE.START && (
        <CountdownTimer onComplete={() => setState(STATE.GAME)} />
      )}

      {state === STATE.GAME && (
        <div className="flex flex-col justify-center items-center flex-grow w-full md:p-5">
          <div className="flex items-end space-x-3 text-white font-semibold text-xl mb-2">
            <span>Time:</span>
            <Stopwatch
              playing={state === STATE.GAME && !finish}
              setDuration={(val) => setDuration(val)}
            />
          </div>
          <div
            className="container flex flex-wrap"
            style={{ width: wBox + "px" }}
          >
            {cards.map((card, index) => {
              return (
                <Card
                  size={sizeCard}
                  key={index}
                  card={card}
                  index={index}
                  isDisabled={shouldDisableAllCards}
                  isInactive={checkIsInactive(card)}
                  isFlipped={checkIsFlipped(index)}
                  onClick={handleCardClick}
                />
              );
            })}
          </div>
        </div>
      )}

      {state === STATE.FINISH && (
        <div className="flex flex-col justify-center items-center flex-grow w-full p-5">
          <CardFinish duration={duration} user={user} game={game} />
        </div>
      )}

      {state === STATE.FAIL && (
        <div className="flex flex-col justify-center items-center flex-grow w-full p-5">
          <CardDiskualifikasi duration={duration} user={user} game={game} config={config} />
        </div>
      )}
    </>
  );
};

export default LiveGameMatchImage;
