import React from "react";

const Card = ({
  onClick,
  card,
  index,
  isInactive,
  isFlipped,
  isDisabled,
  size,
}) => {
  const handleClick = () => {
    !isFlipped && !isDisabled && onClick(index);
  };

  return (
    <div
      //   className={classnames("card", {
      //     "is-flipped": isFlipped,
      //     "is-inactive": isInactive
      //   })}
      style={{ width: size + "px", height: size + "px" }}
      className={`card ${isFlipped ? "is-flipped" : ""} ${
        isInactive ? "is-flipped" : ""
      }`}
      onClick={handleClick}
    >
      <div className="card-face card-font-face bg-white">
        <img
          className="p-1 object-contain"
          src={"https://eventqiu.com/assets/CPL_Tile.jpg"}
          alt=""
        />
      </div>
      <div className="card-face card-back-face bg-white">
        <img src={card.image} alt="" />
      </div>
    </div>
  );
};

export default Card;
