import { useEffect, useState } from "react";
import STATE from "../../../constanta/state";

const CardFinish = (props) => {

  return (
    <>
      <div className="rounded-lg w-full  md:h-max flex flex-col justify-between items-center px-5 md:w-1/3 bg-[#ffffffd9] border-[4px]  py-5">
        <div className="flex flex-col justify-center items-center w-full">
          <div className="my-4 text-3xl text-center font-bold text-black">
            CONGRATULATIONS!
          </div>
        </div>
        <div className="my-5 flex flex-col justify-center items-center">
          <div className="text-xl text-center font-bold text-black">
            YOUR SCORE
          </div>

          <div className="text-6xl text-center font-bold text-black">
            {props.score}
          </div>
        </div>
        <div className="flex mt-4 justify-between w-full">
          <div
            onClick={() => window.location.reload(false)}
            className="font-bold cursor-pointer w-full flex justify-center items-center text-lg text-white py-4 px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
          >
            RESTART
          </div>
          {/* <div
            onClick={() => props.onChangeState(STATE.LEADERBOARD)}
            className="ml-2 flex flex-none w-[60px] h-[60px] font-bold cursor-pointer justify-center items-center text-lg text-white py-4 px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
          >
            <img
              className="h-full object-contain"
              src={"https://eventqiu.com/assets/leaderboard.png"}
              alt=""
            />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CardFinish;
