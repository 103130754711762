import { useState } from "react";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import CardIntro from "./components/cardIntro";
import axios from "axios";
import CONFIG from "../../constanta/config";
import { useEffect } from "react";
import LiveGameMatchImage from "../../game/match-image/matchImage.live";
import LiveGameSearchWord from "../../game/search-word/search.live";
import LiveGameHangman from "../../game/hangman/hangman.live";
import LiveGameScrambleWord from "../../game/scramble-word/scrambleWord.live";
import LiveGameMaze from "../../game/maze/maze.live";
import LiveGameCrossWord from "../../game/cross-word/crossWord.live";
import moment from "moment/moment";

const Campaign = (props) => {
  const [round1, setRound1] = useState([]);
  const [round2, setRound2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [before, setBefore] = useState(false);
  const [after, setAfter] = useState(false);
  const [activity, setActivity] = useState(null);

  useEffect(() => {
    getActivity();
  }, []);

  const getActivity = () => {
    setLoading(true);
    axios
      .get(CONFIG.URL + "/activity?rnd=" + Date.now())
      .then(function (response) {
        setLoading(false);
        if (response?.data?.status === "SUCCESS") {
          let arrRound1 = [];
          let arrRound2 = [];

          let i = 0;
          while (i < response.data?.data?.length) {
            if (
              response.data?.data[i].activity_round.toLowerCase() === "round 1"
            ) {
              arrRound1.push(response.data?.data[i]);
            } else {
              arrRound2.push(response.data?.data[i]);
            }

            i++;
          }

          setRound1(arrRound1);
          setRound2(arrRound2);
          props.setRound1(arrRound1);
          props.setRound2(arrRound2);
        } else {
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const handleChooseActivity = (val) => {
    props.handleChooseActivity(val);
    return
    if (val?.status === "enable") {
      props.handleChooseActivity(val);
    } else {
      setActivity(val);

      if (moment(Date().now) < moment(val.start_time)) {
        setBefore(true);
      } else {
        setAfter(true);
      }
    }
  };

  const handleCloseAlert = () => {
    setBefore(false);
    setAfter(false);
    setActivity(null);
  };

  const renderDate = (item) => {
    let arrStartTime = item.start_time.split(" ");
    let arrendTime = item.end_time.split(" ");
    let sameDay = arrStartTime[0] === arrendTime[0];

    return sameDay
      ? moment(arrStartTime[0]).format("MMMM, Do YYYY")
      : moment(arrStartTime[0]).format("MMMM, Do YYYY") +
          " - " +
          moment(arrendTime[0]).format("MMMM, Do YYYY");
  };

  const renderTime = (date) => {
    return moment(date).format("h:mm a");
  };

  return (
    <div className="w-full px-0 flex justify-center items-center">
      {/* MODAL INFO BEFORE*/}
      {before && (
        <div className="fixed z-50 top-0 left-0 bg-[#000000ab] h-screen w-screen flex justify-center items-center">
          <div className="container w-max px-5 py-4 ">
            Sorry, <span className="font-bold">{activity?.activity_name}</span>{" "}
            has not yet started.
            <br />
            You can access this Activity on
            <br />
            <div className="">
              <span className="font-bold">{renderDate(activity)}</span> from{" "}
              <span className="font-bold">
                {renderTime(activity?.start_time)}
              </span>{" "}
              to{" "}
              <span className="font-bold">
                {renderTime(activity?.end_time)}
              </span>
            </div>
            <div
              onClick={() => handleCloseAlert()}
              className="mt-4 py-3 px-5 w-max mx-auto font-bold cursor-pointer flex justify-center items-center text-sm  text-white  bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
            >
              OK
            </div>
          </div>
        </div>
      )}

      {/* MODAL INFO AFTER*/}
      {after && (
        <div className="fixed z-50 top-0 left-0 bg-[#000000ab] h-screen w-screen flex justify-center items-center">
          <div className="container w-max px-5 py-4 ">
            Sorry, <span className="font-bold">{activity?.activity_name}</span>{" "}
            ended.
            <br />
            Access to this Activity was available on
            <br />
            <div className="">
              <span className="font-bold">{renderDate(activity)}</span> from{" "}
              <span className="font-bold">
                {renderTime(activity?.start_time)}
              </span>{" "}
              to{" "}
              <span className="font-bold">
                {renderTime(activity?.end_time)}
              </span>
            </div>
            <div
              onClick={() => handleCloseAlert()}
              className="mt-4 py-3 px-5 w-max mx-auto font-bold cursor-pointer flex justify-center items-center text-sm  text-white  bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
            >
              OK
            </div>
          </div>
        </div>
      )}

      <div className="rounded-lg w-full md:h-max flex flex-col justify-between items-start px-5 md:w-2/3 bg-[#ffffffd9] border-[4px] py-2 md:py-4">
        {loading ? (
          <div className="font-bold text-center w-full">Loading...</div>
        ) : (
          <div className="flex flex-col justify-center items-center w-full">
            <div className="uppercase font-bold text-2xl">
              {props?.config?.title}
            </div>
            <div className="grid grid-cols-2 gap-4 w-full mt-4">
              <div className="w-full">
                <div className="uppercase font-bold w-full">ROUND 1</div>
                <div className="space-y-2 mt-4">
                  {round1.map((item, key) => {
                    let arrStartTime = item.start_time.split(" ");
                    let arrendTime = item.end_time.split(" ");
                    let sameDay = arrStartTime[0] === arrendTime[0];

                    return (
                      <div
                        onClick={() => handleChooseActivity(item)}
                        key={key}
                        className={`${
                          item.status === "enable"
                            ? "bg-[#42ade2d4] hover:bg-[#42ade2]"
                            : "bg-red-600"
                        } cursor-pointer flex flex-col font-bold  w-full justify-center items-center text-lg text-white py-4 px-2 rounded-lg`}
                      >
                        <div> {item.activity_name}</div>
                        <div className="text-sm">
                          {sameDay
                            ? moment(arrStartTime[0]).format("MMMM, Do YYYY")
                            : moment(arrStartTime[0]).format("MMMM, Do YYYY") +
                              " - " +
                              moment(arrendTime[0]).format("MMMM, Do YYYY")}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-full">
                <div className="uppercase font-bold w-full">ROUND 2</div>
                <div className="space-y-2 mt-4">
                  {round2.map((item, key) => {
                    let arrStartTime = item.start_time.split(" ");
                    let arrendTime = item.end_time.split(" ");
                    let sameDay = arrStartTime[0] === arrendTime[0];

                    return (
                      <div
                        onClick={() => handleChooseActivity(item)}
                        key={key}
                        className={`${
                          item.status === "enable"
                            ? "bg-[#42ade2d4] hover:bg-[#42ade2]"
                            : "bg-red-600"
                        }  cursor-pointer flex flex-col font-bold  w-full justify-center items-center text-lg text-white py-4 px-2 rounded-lg`}
                      >
                        <div> {item.activity_name}</div>
                        <div className="text-sm">
                          {" "}
                          {sameDay
                            ? moment(arrStartTime[0]).format("MMMM, Do YYYY")
                            : moment(arrStartTime[0]).format("MMMM, Do YYYY") +
                              " - " +
                              moment(arrendTime[0]).format("MMMM, Do YYYY")}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center items-center mt-5">
              <a
                href="/RBLCPL2023/leaderboard"
                className="w-full md:w-1/2 font-bold cursor-pointer flex justify-center items-center text-lg text-white py-4 px-2 bg-[#42ade2d4] hover:bg-[#42ade2] rounded-lg "
              >
                LEADERBOARD
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Campaign;
