import { useEffect, useState } from "react";

const CountdownTimer = (props) => {
  const [timeLeft, setTimeLeft] = useState(4);

  useEffect(() => {
    if (timeLeft === 0) {
      console.log("TIME LEFT IS 0");
      setTimeLeft(0);
      setTimeout(() => {
        props.onComplete();
      }, 1000);
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const renderTime = () => {
    if (timeLeft === 4) return "READY!";
    else if (timeLeft === 0) return "GO!";
    else return timeLeft;
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center flex-grow w-full p-5 text-white text-8xl">
        <span>{renderTime()}</span>
      </div>
    </>
  );
};

export default CountdownTimer;
