import { useState, useMemo, useEffect } from "react";
import { generateMaze, solve } from "./util";
import styles from "./style.module.css";
import STATE from "../../constanta/state";
import CardStart from "./components/cardStart";
import imgArrow from "../../images/arrow.png";
import CardFinish from "./components/cardFinish";
import CardLeaderboard from "../cross-word/components/cardLeaderboard";

export default function GameMaze() {
  const [state, setState] = useState(STATE.START);
  const [name, setName] = useState("");
  const [counter, setCounter] = useState(0);
  // useEffect(() => {
  //   if (state === STATE.GAME) {
  //     const timer =
  //       counter > 0 && setInterval(() => setCounter(counter + 1), 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [state, counter]);

  const [gameId, setGameId] = useState(1);
  const [status, setStatus] = useState("playing");

  const [size, setSize] = useState(20);
  const [cheatMode, setCheatMode] = useState(false);

  const [userPosition, setUserPosition] = useState([0, 0]);

  const maze = useMemo(() => generateMaze(size, size), [size, gameId]);
  const solution = useMemo(() => {
    const s = new Set();
    const solutionPath = solve(maze, userPosition[0], userPosition[1]);
    solutionPath.forEach((path) => {
      const [x, y] = path;
      s.add(String(x) + "-" + String(y));
    });
    return s;
  }, [size, userPosition[0], userPosition[1], gameId]);

  useEffect(() => {
    const lastRowIndex = maze.length - 1;
    const lastColIndex = maze[0].length - 1;
    if (userPosition[0] === lastRowIndex && userPosition[1] === lastColIndex) {
      setState(STATE.FINISH);
      setStatus("won");
    }
  }, [userPosition[0], userPosition[1]]);

  const makeClassName = (i, j) => {
    const rows = maze.length;
    const cols = maze[0].length;
    let arr = [styles.wall];
    if (maze[i][j][0] === 0) {
      arr.push(styles.topWall);
    }
    if (maze[i][j][1] === 0) {
      arr.push(styles.rightWall);
    }
    if (maze[i][j][2] === 0) {
      arr.push(styles.bottomWall);
    }
    if (maze[i][j][3] === 0) {
      arr.push(styles.leftWall);
    }
    if (i === rows - 1 && j === cols - 1) {
      arr.push(styles.destination);
    }
    if (i === userPosition[0] && j === userPosition[1]) {
      arr.push(styles.currentPosition);
    }

    if (cheatMode && solution.has(String(i) + "-" + String(j))) {
      arr.push(styles.sol);
    }
    return arr.join(" ");
  };

  const handleMove = (e) => {
    e.preventDefault();
    if (status !== "playing") {
      return;
    }
    const key = e.code;

    const [i, j] = userPosition;
    if ((key === "ArrowUp" || key === "KeyW") && maze[i][j][0] === 1) {
      setUserPosition([i - 1, j]);
    }
    if ((key === "ArrowRight" || key === "KeyD") && maze[i][j][1] === 1) {
      setUserPosition([i, j + 1]);
    }
    if ((key === "ArrowDown" || key === "KeyS") && maze[i][j][2] === 1) {
      setUserPosition([i + 1, j]);
    }
    if ((key === "ArrowLeft" || key === "KeyA") && maze[i][j][3] === 1) {
      setUserPosition([i, j - 1]);
    }
  };

  const handleMoveButton = (key) => {
    if (status !== "playing") {
      return;
    }

    const [i, j] = userPosition;
    if (key === "up" && maze[i][j][0] === 1) {
      setUserPosition([i - 1, j]);
    }
    if (key === "right" && maze[i][j][1] === 1) {
      setUserPosition([i, j + 1]);
    }
    if (key === "down" && maze[i][j][2] === 1) {
      setUserPosition([i + 1, j]);
    }
    if (key === "left" && maze[i][j][3] === 1) {
      setUserPosition([i, j - 1]);
    }
  };

  const handleUpdateSettings = () => {
    setSize(20);
    setUserPosition([0, 0]);
    setStatus("playing");
    setGameId(gameId + 1);
  };

  const handleChangeState = (val) => {
    setState(val);
  };

  return (
    <div
      className="App flex justify-start items-start flex-col min-h-screen"
      style={{
        background:
          "url('https://eventqiu.com/assets/bg_game_dark.jpg') no-repeat center center fixed",
        backgroundSize: "cover",
      }}
    >
      {/* START */}
      {state === STATE.START && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardStart
            onChangeState={(val) => handleChangeState(val)}
            setName={(val) => setName(val)}
          />
        </div>
      )}

      {/* GAME */}
      {state === STATE.GAME && (
        <>
          <header className="bg-[#ffffffd9] px-3 md:px-5 flex flex-none justify-between items-center w-full h-14">
            <h3 className="text-xl d:text-3xl font-bold text white w-full text-left">
              Maze Game
            </h3>
            <div className="flex flex-none justify-center items-center  max-w-max space-x-2">
              <div className="text-black font-semibold">
                User:{" "}
                <span className="text-black0 font-bold">{name}</span>{" "}
              </div>
              <span className="mx-2">|</span>
              <div className="text-black font-semibold">
                Timer: <span className="text-red-500 font-bold">{counter}</span>{" "}
              </div>
            </div>
          </header>

          {/* <header className="bg-[#ffffffd9] px-5 py-2 mb-5">
            <h3 className="text-3xl font-bold text white">Maze Game</h3>
          </header>
          <div className="flex space-x-5 md:w-[500px] justify-between mb-10">
            <div className="hidden md:flex font-semibold score space-x-5 justify-between bg-[#ffffffd9] px-5 py-2 mb-4 rounded-xl">
              <div className="text-black font-semibold">
                use <strong>WASD</strong> or <strong>ARROW KEY</strong>
              </div>
            </div>
            <div className="font-semibold score flex space-x-5 justify-between bg-[#ffffffd9] px-5 py-2 mb-4 rounded-xl">
              <div className="text-black font-semibold">
                User: <span className="text-black0 font-bold">{name}</span>{" "}
              </div>
              <div className="text-black font-semibold">
                Timer: <span className="text-red-500 font-bold">{counter}</span>{" "}
              </div>
            </div>
          </div> */}
          <div className="flex justify-center items-center flex-grow w-full p-5">
            <div className="container relative" style={{ borderRadius: 0, background: "#42ade2" }}>
              <div className="absolute -top-8 -left-2 bg-[#fa8072] py-1 px-2 rounded-full text-black font-bold text-[10px]">
                START
              </div>
              <div className="absolute -bottom-8 -right-2 bg-[#00ff00] py-1 px-2 rounded-full text-black font-bold text-[10px]">
                FINISH
              </div>

              <div
                className={`relative w-[350px] h-[350px] md:w-[450px] md:h-[450px] mx-auto outline-none focus-within:outline-none focus:outline-none`}
                onKeyDown={handleMove}
                tabIndex={-1}
              >
                <table id="maze" className="w-full h-full">
                  <tbody>
                    {maze.map((row, i) => (
                      <tr key={`row-${i}`}>
                        {row.map((cell, j) => (
                          <td
                            key={`cell-${i}-${j}`}
                            className={makeClassName(i, j)}
                          >
                            <div />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

                {status !== "playing" && (
                  <div className={styles.info} onClick={handleUpdateSettings}>
                    <p>you won (click here to play again)</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={`md:hidden py-10 flex justify-between items-center  w-[350px] h-[50px] md:w-[500px] md:h-[50px] mx-auto outline-none focus-within:outline-none focus:outline-none`}
          >
            <div className="flex space-x-4">
              <div
                onClick={() => handleMoveButton("up")}
                className=" cursor-pointer bg-white hover:bg-green-100 rounded-md p-2"
              >
                <img
                  src={imgArrow}
                  alt=""
                  className="-rotate-90 h-[40px] w-[40px] object-contain"
                />
              </div>
              <div
                onClick={() => handleMoveButton("down")}
                className=" cursor-pointer bg-white hover:bg-green-100 rounded-md p-2"
              >
                <img
                  src={imgArrow}
                  alt=""
                  className="rotate-90  h-[40px] w-[40px] object-contain"
                />
              </div>
            </div>

            <div className=" flex space-x-4">
              <div
                onClick={() => handleMoveButton("left")}
                className=" cursor-pointer bg-white hover:bg-green-100 rounded-md p-2"
              >
                <img
                  src={imgArrow}
                  alt=""
                  className="rotate-180 h-[40px] w-[40px] object-contain"
                />
              </div>
              <div
                onClick={() => handleMoveButton("right")}
                className=" cursor-pointer bg-white hover:bg-green-100 rounded-md p-2"
              >
                <img
                  src={imgArrow}
                  alt=""
                  className="h-[40px] w-[40px] object-contain"
                />
              </div>
            </div>
          </div>
        </>
      )}

      {/* FINISH */}
      {state === STATE.FINISH && (
        <div className="flex justify-center items-center flex-grow w-full p-5">
          <CardFinish
            name={name}
            onChangeState={(val) => handleChangeState(val)}
            score={0}
          />
        </div>
      )}

      {/* LEADERBOARD */}
      {state === STATE.LEADERBOARD && (
        <>
          <CardLeaderboard onChangeState={(val) => handleChangeState(val)} />
        </>
      )}
    </div>
  );
}
